import React from 'react'
import { useColorModeValue } from '@chakra-ui/react'

export function RasaLogo(props) {
    const color = useColorModeValue('#000', '#fff')
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1000"
        height="50"
        version="1"
        viewBox="0 0 884 538"
          {...props}
      >
        <path
          d="M64 5337c-2-7-3-80-2-162l3-150h8710l3 163 2 162H4425c-3554 0-4357-2-4361-13zM65 4768c-3-7-4-1070-3-2363L65 55h380l5 1075 5 1074 124 18c68 9 187 34 265 54 215 56 192 59 257-33 101-140 177-290 227-443 51-156 51-147 57-964 3-423 8-773 11-778 3-4 91-8 195-8h190l-4 828c-3 820-3 828-25 907-71 253-168 463-314 682-61 90-68 106-57 121 8 9 32 40 55 67 74 90 151 208 203 312 56 114 70 149 108 273l25 85 8 715c4 393 5 721 3 728-4 9-185 12-859 12-675 0-856-3-859-12zm1304-393c14-4 16-52 14-491-3-559-4-568-93-749-135-273-347-426-706-506-55-12-108-19-117-16-16 6-17 76-17 881 0 671 3 875 12 878 18 6 888 9 907 3zM2395 4768c-3-7-4-1070-3-2363l3-2350h380l5 1070 5 1070h930l5-1070 5-1070h390v4720l-858 3c-681 2-859 0-862-10zm1313-396c9-2 12-184 12-874 0-480-4-879-8-886-7-10-107-12-468-10l-459 3-3 879c-1 521 2 882 7 885 8 5 883 7 919 3zM4727 4773c-4-3-7-436-7-962 0-1121-16-995 138-1107 86-63 533-392 717-529 39-28 149-109 245-180 96-70 187-139 203-153l27-26-2-680-3-681h-930l-5 617-5 616-107 79c-58 43-144 107-190 142l-83 64-3-949c-1-522 0-955 3-962 3-10 181-12 862-10l858 3 3 941 2 942-28 53c-25 47-52 71-277 241-369 279-778 585-879 658-49 36-105 79-123 96l-33 32v675c0 606 2 675 16 681 9 3 213 6 454 6s445-3 454-6c14-6 16-66 16-585v-579l198 2 197 3v1560l-856 3c-470 1-859-1-862-5zM7055 4768c-3-7-4-1070-3-2363l3-2350 184-3c127-2 188 1 197 9 12 10 14 183 14 1075v1064l463-2 462-3 5-1070 5-1070h390v4720l-858 3c-681 2-859 0-862-10zm1313-396c10-7 12-191 10-888l-3-879-462-3-463-2v883c0 486 3 886 6 890 10 9 897 8 912-1z"
          transform="matrix(.1 0 0 -.1 0 538)"
          fill={"#fff"}
        ></path>
      </svg>
    )
  }
  