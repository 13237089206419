import 'react-international-phone/style.css';
import { useCallback } from 'react';
import { Text, Button, VStack, HStack } from '@chakra-ui/react';
import OtpInput from 'react18-input-otp';

import { motion } from 'framer-motion';

export default function ConfirmCode({
  phoneNumber,
  setCode,
  code,
  setOtpLoading,
  stytchClient,
  toast,
  methodId,
  otpLoading,
  loading,
  resetLogin,
  gradient,
}) {
  const login = useCallback(() => {
    console.log(code);
    setOtpLoading(true);
    stytchClient.otps
      .authenticate(code, methodId, {
        session_duration_minutes: 60,
      })
      .then(resp => {
        console.log('resp', resp);
        setOtpLoading(false);
      })
      .catch(err => {
        toast({
          title: 'Error',
          description: 'Invalid OPT code, try sending a new one.',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
        setOtpLoading(false);
        console.log('err', err);
      });
  }, [stytchClient, code, methodId]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <VStack width={'90vw'} justifyContent="end" alignItems="start">
        <Text
          fontSize="sm"
          color="#BBB8CC"
          opacity="0.9"
          textAlign={'left'}
          marginLeft=".8rem"
        >
          Please enter the unique code sent to <br />
          your phone.
        </Text>
        <HStack spacing={'10px'} alignSelf="center">
          <OtpInput
            inputStyle={{
              width: '12vw',
              height: '12vw',
              fontSize: '5vw',
              borderRadius: '12px',
              border: '1px #282536 solid',
              color: '#EEEDF2',
              backgroundColor: '#0A010A',
              margin: '0px',
              textAlign: 'center',
              fontWeight: 'bold',
            }}
            focusStyle={{
              outline: 'none',
              border: '1px #5555FF solid',
              boxShadow: '0px 0px 10px #282536',
            }}
            isInputNum={true}
            value={code}
            onChange={value => setCode(value)}
            numInputs={6}
            separator={<span>&nbsp;&nbsp;</span>}
          />
        </HStack>

        <Button
          onClick={login}
          isDisabled={code.length < 6}
          isLoading={otpLoading || loading}
          marginTop={'16px'}
          marginBottom={'20px'}
          border={'1px solid gray.700'}
          borderRadius={'40px'}
          width={'85vw'}
          height={'50px'}
          alignSelf="center"
          color={code.length === 6 ? '#0C0A18' : '#3F3C55'}
          background={
            code.length === 6 ? '#DFDFF2' : 'rgba(147.25, 142.86, 197.77, 0.10)'
          }
          boxShadow={
            code.length === 6 ? '4px 3px 20px rgba(85, 102, 255, 0.30)' : 'none'
          }
          fontSize="1.2rem"
          zIndex={1}
        >
          NEXT
        </Button>
        <Text
          fontSize="xs"
          color="#BBB8CC"
          opacity="0.9"
          textAlign={'center'}
          alignSelf="center"
          marginTop=".5rem"
        >
          Didn't receive a code?
        </Text>
        <Text
          fontSize="xs"
          color="#B3B3FF"
          textAlign={'center'}
          onClick={resetLogin}
          alignSelf="center"
        >
          Resend Code
        </Text>
      </VStack>
    </motion.div>
  );
}
