import React from 'react';

import {
  HStack,
  keyframes,
  Text,
  Button,
  Box,
  useToast,
  VStack,
  Input,
  InputLeftAddon,
  FormControl,
  FormLabel,
  InputGroup,
  Select,
} from '@chakra-ui/react';

import { useStytch, useStytchUser } from '@stytch/react';

import { RasaLogo } from './logo';
import { useMutation } from 'react-query';
import { createGuest, useGuest, createNewUserWithGuestlist } from '../api';

export function SignUpInvitedGuestListPage() {
  const gradient = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

  const params = new URLSearchParams(window.location.search);

  const eventSlug = params.get('event-slug');
  const inviteID = params.get('guestWhoInvited');

  const stytchClient = useStytch();
  const stytchUser = useStytchUser();
  const { guest, loading } = useGuest(stytchUser?.user?.user_id);
  const toast = useToast();

  const createNewUserWithGuestlistMutation = useMutation(
    createNewUserWithGuestlist
  );

  const [formFilled, setFormFilled] = React.useState(false);
  React.useEffect(() => {
    console.log(stytchUser?.user?.user_id);
    console.log(guest);

    if (stytchUser?.user?.user_id) {
      if (loading) return;

      if (guest?.approvalStatus === 'approved') {
        window.location.href = '/inviteFriends';
      } else if (guest?.approvalStatus === 'pending') {
        window.location.href = '/confirmSignup';
      } else {
        return;
      }
    }
  }, [stytchClient, stytchUser, guest]);

  const [formState, setFormState] = React.useState({
    firstName: '',
    lastName: '',
    email: '',
    igHandle: '',
    location: '',
    phone: stytchUser?.user?.phone_numbers[0].phone_number,
  });

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;

    setFormState(prevState => ({
      ...prevState,
      [name]: value,
    }));

    if (
      formState.firstName &&
      formState.lastName &&
      formState.igHandle &&
      formState.location
    ) {
      // Check if email is valid
      if (formState.email) {
        if (!formState.email.includes('@')) {
          return;
        }
      }
      setFormFilled(true);
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    console.log(formState);
    submitForm(formState);
  };

  const submitForm = formState => {
    // Post to the API
    createNewUserWithGuestlistMutation
      .mutateAsync({
        event_slug: eventSlug,
        invite_id: inviteID,
        auth_service_id: stytchUser?.user?.user_id,
        signUpData: formState,
      })
      .then(resp => {
        if (resp?.approvalStatus == 'approved') {
          toast({
            title: 'Account created, redirecting to the event page.',
            description:
              "Your account has been created, you'll be redirected to the event page.",
            status: 'success',
            duration: 9000,
            isClosable: true,
          });
          window.location.href = `/specificEvent/${eventSlug}`;
        } else {
          toast({
            title: 'An error occurred.',
            description: 'We were unable to create your account.',
            status: 'error',
            duration: 9000,
            isClosable: true,
          });
        }
      })
      .catch(err => {
        console.log(err);
        toast({
          title: 'An error occurred.',
          description: 'We were unable to create your account.',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      });
  };

  return (
    <>
      <Box
        h="135vh"
        w="100vw"
        alignContent="center"
        justifyContent="center"
        bgSize="cover"
        bgPosition="center"
        display="flex"
        bgImage={
          'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/images/inviteGifBackground.gif)'
        }
        bgRepeat="no-repeat"
      >
        <VStack>
          <Box marginTop="5vh">
            <RasaLogo />
          </Box>

          <Box
            marginTop="5vh"
            w="90vw"
            bgColor="#0C0A29"
            borderRadius="18px"
            borderColor="gray.700"
          >
            <VStack>
              <Text
                marginTop={'5vh'}
                fontSize="2xl"
                bgGradient="linear(to-l, #BDD7F180, #FFFFFF)"
                bgClip="text"
              >
                Request access
              </Text>

              <Text
                marginLeft={'5vw'}
                marginRight={'5vw'}
                fontSize="md"
                color="#6F87A0EB"
                opacity="0.92"
                textAlign={'center'}
              >
                RASA was born to bring captivating minds to a common ground.
                <br />
                ⚡️ <br />
                This is an invite-only experience. We’re hand picking guests to
                curate the most memorable journey!
              </Text>
              <form onSubmit={handleSubmit}>
                <FormControl marginTop={'5vh'} marginLeft={'20px'}>
                  <FormLabel
                    fontWeight={'bold'}
                    fontSize={'xs'}
                    textColor={'#6F87A0EB'}
                    opacity={'0.92'}
                  >
                    FIRST NAME
                  </FormLabel>
                  <Input
                    bgColor={'#050B1180'}
                    borderWidth={'1px'}
                    borderRadius={'16px'}
                    opacity="1.0"
                    marginBottom={'1vh'}
                    width={'80vw'}
                    type="text"
                    placeholder="Your First Name"
                    name="firstName"
                    onChange={handleChange}
                  />

                  <FormLabel
                    fontSize={'xs'}
                    textColor={'#6F87A0EB'}
                    opacity={'0.92'}
                  >
                    LAST NAME
                  </FormLabel>
                  <Input
                    bgColor={'#050B1180'}
                    borderWidth={'1px'}
                    borderRadius={'16px'}
                    opacity="1.0"
                    marginBottom={'1vh'}
                    width={'80vw'}
                    type="text"
                    placeholder="Your Last Name"
                    name="lastName"
                    onChange={handleChange}
                  />

                  <FormLabel
                    fontSize={'xs'}
                    textColor={'#6F87A0EB'}
                    opacity={'0.92'}
                  >
                    EMAIL
                  </FormLabel>
                  <InputGroup>
                    <Input
                      bgColor={'#050B1180'}
                      borderWidth={'1px'}
                      borderRadius={'16px'}
                      opacity="1.0"
                      marginBottom={'1vh'}
                      width={'80vw'}
                      type="email"
                      placeholder="Enter Email"
                      name="email"
                      onChange={handleChange}
                    />
                  </InputGroup>

                  <FormLabel
                    fontSize={'xs'}
                    textColor={'#6F87A0EB'}
                    opacity={'0.92'}
                  >
                    INSTAGRAM
                  </FormLabel>
                  <InputGroup>
                    <InputLeftAddon children="@" />
                    <Input
                      bgColor={'#050B1180'}
                      borderWidth={'1px'}
                      borderRadius={'16px'}
                      opacity="1.0"
                      marginBottom={'1vh'}
                      width={'60vw'}
                      type="text"
                      placeholder="IG Handle"
                      name="igHandle"
                      onChange={handleChange}
                    />
                  </InputGroup>

                  <FormLabel
                    fontSize={'xs'}
                    textColor={'#6F87A0EB'}
                    opacity={'0.92'}
                  >
                    LOCATION
                  </FormLabel>
                  <Input
                    bgColor={'#050B1180'}
                    borderWidth={'1px'}
                    borderRadius={'16px'}
                    opacity="1.0"
                    marginBottom={'1vh'}
                    width={'80vw'}
                    type="text"
                    placeholder="City"
                    name="location"
                    onChange={handleChange}
                  />

                  <Button
                    marginTop={'3vh'}
                    marginBottom={'3vh'}
                    borderColor={'gray.700'}
                    borderRadius={'71px'}
                    borderWidth={'1px'}
                    opacity={'0.92'}
                    width={'80vw'}
                    animation={`${gradient} 1s ease infinite`}
                    bgGradient="linear(to-l, #BDD7F180, #FFFFFF)"
                    bgClip="text"
                    type="submit"
                    isDisabled={!formFilled}
                  >
                    Submit
                  </Button>
                </FormControl>
              </form>
            </VStack>
          </Box>
        </VStack>
      </Box>
    </>
  );
}
