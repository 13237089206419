import PropTypes from "prop-types";
import { Button, Box, Heading, Text, Image, Flex, VStack } from "@chakra-ui/react";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DoorsOpenIcon from "./DoorsOpenIcon";

export default function VenueDetails({
  venueName,
  venueAddress,
  venuePicture,
  doorsOpen,
}) {
  const handleMapRedirect = () => {
    const mapsLink = `https://www.google.com/maps?q=${encodeURIComponent(
      venueAddress
    )}`;
    window.open(mapsLink, "_blank");
  };

  return (
    <Box p={5} borderRadius="md" boxShadow="sm" mb={"150"}>
      <Flex direction={{ base: "row", md: "column" }}align="center">
        <VStack align="start" flex="1">
          <Heading size="md" mb={1}>Venue</Heading>
          <Text fontSize="lg">{venueName}</Text>
          <Text fontSize="sm" color="gray.400" mb={4}>{venueAddress}</Text>
          <Button
            leftIcon={<FontAwesomeIcon icon={faMapMarkerAlt} />}
            borderRadius="full"
            onClick={handleMapRedirect}
          >
            OPEN IN MAPS
          </Button>
        </VStack>
        <Box flex="1" alignSelf="end">
          <Image
            alt="Album cover"
            borderRadius="md"
            boxShadow="md"
            src={"https://lh3.googleusercontent.com/p/AF1QipPdBbVaeZha86x10f7RYIVvRglVnx3N8XMAIMrA=s1360-w1360-h1020"}
            objectFit="cover"
          />
        </Box>
      </Flex>

      <VStack align="start" mt={4} gap={2}>
        <Flex align="center" gap={2}>
          <DoorsOpenIcon />
          <Text fontSize="sm" color="gray.400">Doors Open</Text>
          <Text fontSize="sm">9:00PM</Text>
        </Flex>
      </VStack>
    </Box>
  );
}

VenueDetails.propTypes = {
  venueName: PropTypes.string.isRequired,
  venueAddress: PropTypes.string.isRequired,
  venuePicture: PropTypes.string,
  doorsOpen: PropTypes.string,
};
