import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Image,
  Text,
  VStack,
  useStyleConfig,
} from '@chakra-ui/react';

export default function EventOverview({ flier, title, location, date }) {
  return (
    <VStack p={3} borderRadius="lg" className="font-popins" justifyContent="center" alignItems="center" textColor={"white"}>
      <VStack align="start" spacing={1}>
        <Image
          alt="Event flier"
          src={flier}
          borderRadius="xl"
          objectFit="cover"
          width={["auto", "840px"]}
        />
        <Text fontSize={['4xl', '5xl', '6xl']}>{title}</Text>
        <Text textTransform="uppercase" fontSize={['md', 'lg', 'xl']}>Location: {location}</Text>
        <Text textTransform="uppercase" color="purple.500" fontSize={['sm', 'md', 'lg']}>
          Date: {date} - 07/20/2024
        </Text>
        <Text fontSize={['sm', 'md', 'lg']} fontWeight="normal" color="purple.500">
          9:00 PM - 2:00 AM
        </Text>
        <Text fontSize={['sm', 'md', 'lg']} fontWeight="normal" color="purple.500">
          9077 Santa Monica Blvd, West Hollywood, CA 90069
        </Text>
        
      </VStack>
    </VStack>
  );
}

EventOverview.propTypes = {
  title: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  flier: PropTypes.string,
};
