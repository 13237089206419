import React from 'react';
import {
  Box,
  Button,
  Text,
  HStack,
  Flex,
  Link,
  Spinner,
  VStack,
  FormControl,
  FormErrorMessage,
  Input,
  FormLabel,
  Divider,
  Image,
} from '@chakra-ui/react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';

import { NoTicketSVG } from './noTicketsSVG';
import { useGuest } from '../api';
import { useStytchUser } from '@stytch/react';
import { LoadingScreen } from '../loader';
import { createTableRequest, rsvpEvent } from '../api';
import { TicketsCarousel } from './ticketCarousel';
import { NEXT_PUBLIC_DEPLOY_URL } from '../constants';
export function TicketsPage({ event }) {
  const stytchUser = useStytchUser();
  const { guest, loading } = useGuest(stytchUser?.user?.user_id);
  console.log(guest);

  const [tableRequestLoading, setTableRequestLoading] = React.useState(false);
  const [tableRequestFinished, setTableRequestFinished] = React.useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { slug } = event;

  console.log(guest?.events[slug]);

  console.log('Hellow World!');

  const [validationErrors, setValidationErrors] = React.useState({});

  const [infoModalData, setInfoModalData] = React.useState({
    email: '',
    zipCode: '',
    igHandle: '',
  });

  const validateForm = () => {
    const errors = {};
    if (!infoModalData.email.match(/^\S+@\S+\.\S+$/)) {
      errors.email = 'Invalid email address';
    }
    if (!infoModalData.zipCode.match(/^\d{5}(-\d{4})?$/)) {
      errors.zipCode = 'Invalid zip code';
    }
    if (infoModalData.igHandle.trim() === '') {
      errors.igHandle = 'Instagram handle is required';
    }
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const {
    isOpen: isInfoModalOpen,
    onOpen: onInfoModalOpen,
    onClose: onInfoModalClose,
  } = useDisclosure();

  React.useEffect(() => {
    console.log('Guest:', guest);
    if (!loading && (guest.email === '' || !guest.zipCode || !guest.igHandle)) {
      onInfoModalOpen();
    }
  }, [loading, guest, onInfoModalOpen]);

  const handleInputChange = e => {
    const { name, value } = e.target;
    setInfoModalData(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmitInfo = async () => {
    if (!validateForm()) return;
    // Call API to update guest information
    const response = await fetch(
      `${NEXT_PUBLIC_DEPLOY_URL}/api/patchMissingData/${guest?.auth_service_id}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(infoModalData),
      }
    );
  
    const data = await response.json();


    onInfoModalClose();
    // Reload or update local guest data as needed
  };

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <Box w="100vw" bgColor={'#000000'}>
      <VStack>
        <Flex
          direction="column"
          alignItems="flex-start"
          position="absolute"
          gap="20px"
          w="100vw"
          bgColor={'#000000'}
        >
          <Text
            w="350px"
            h="18px"
            ml="16px"
            fontWeight="500"
            fontSize={['12px', '24px']}
            lineHeight="18px"
            letterSpacing="0.3px"
            textTransform="uppercase"
            color="rgba(255, 255, 255, 0.92)"
          >
            Your Event Passes
          </Text>
          <Divider />

          {event.event_type === 'paid' ? (
            // Existing logic for paid events
            guest?.events[slug]?.tickets?.length == 0 ||
            !guest?.events[slug]?.tickets?.length ? (
              <Box
                alignContent={'center'}
                justifyContent={'center'}
                display={'flex'}
                onClick={() => (window.location.href = '/loginPublicCheckout')}
              >
                <NoTicketSVG />
              </Box>
            ) : (
              <TicketsCarousel tickets={guest?.events[slug]?.tickets} />
            )
          ) : (
            <Box
              w="100vw"
              h={'104px'}
              alignContent={'center'}
              justifyContent={'center'}
              display={'flex'}
            >
              {!guest?.events[slug]?.rspved && (
                <Button
                  borderRadius={'71px'}
                  background={
                    'linear-gradient(64deg, #9763B4 0%, #8978FB 100%)'
                  }
                  boxShadow={'0px 7px 80px -12px rgba(148, 104, 195, 0.20)'}
                  isDisabled={guest?.events[slug]?.rspved}
                  onClick={() => {
                    console.log(slug);
                    rsvpEvent(guest?.auth_service_id, slug).then(() => {
                      window.location.reload();
                    });
                  }}
                >
                  CLICK TO GET ON THE WAITLIST
                </Button>
              )}
              {guest?.events[slug]?.rspved && (
                <Button
                  borderRadius={'71px'}
                  colorScheme="green"
                  boxShadow={'0px 7px 80px -12px rgba(148, 104, 195, 0.20)'}
                >
                  <VStack gap="0">
                    <Text>YOU'RE ON THE WAITLIST</Text>
                    <Text>WE WILL NOTIFY YOU WHEN TICKETS ARE LIVE</Text>
                  </VStack>
                </Button>
              )}
            </Box>
          )}

          <Divider />

          {/* For the table request card */}
          <Flex
            width="100vw" // viewport width
            justifyContent="center" // center horizontally
            alignItems="center" // center vertically
          >
            <Flex
              direction="column"
              alignItems="center"
              w="350px"
              bg="#130213"
              border="0.5px solid #2E092A"
              boxShadow="0px 0px 10px rgba(85, 102, 255, 0.16)"
              borderRadius="16px"
              borderWidth={'1px'}
              gap="16px"
              p="24px 24px 24px 24px"
            >
              <Text
                fontWeight="500"
                fontSize="20px"
                lineHeight="18px"
                letterSpacing="0.3px"
                color="rgba(255, 255, 255, 0.92)"
              >
                Request A Table
              </Text>
              <Text
                w="318px"
                h="38px"
                fontWeight="500"
                fontSize="12px"
                lineHeight="18px"
                letterSpacing="0.3px"
                color="#9795B5"
                mb="16px"
                textAlign={'center'}
              >
                Submit a request and we will reach out soon!
                <br />
                Or contact us on team@rasa.world
              </Text>
              <Button
                w="119px"
                h="52px"
                boxShadow="0px 7px 80px -12px #5566FF"
                borderRadius="840px"
                color="#FFFFFF"
                fontSize="16px"
                lineHeight="20px"
                letterSpacing="0.15px"
                borderColor={'white'}
                borderWidth={'1px'}
                onClick={onOpen}
              >
                REQUEST
              </Button>

              <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>REQUEST TABLE</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    {tableRequestLoading ? (
                      <Flex direction="column" alignItems="center">
                        <Spinner mb="2" />
                        <Text>Processing your request...</Text>
                      </Flex>
                    ) : tableRequestFinished ? (
                      <Text>
                        Your table request has been confirmed, we will reach out
                        soon! 🎉
                      </Text>
                    ) : (
                      <Text>
                        Are you sure you want to request a table for this event?
                      </Text>
                    )}
                  </ModalBody>

                  <ModalFooter>
                    <Button
                      colorScheme="blue"
                      mr={3}
                      isDisabled={tableRequestLoading || tableRequestFinished}
                      onClick={() => {
                        setTableRequestLoading(true);
                        createTableRequest(guest?._id, slug);
                        // Wait 2 seconds to simulate a request
                        setTimeout(() => {
                          setTableRequestLoading(false);
                          setTableRequestFinished(true);
                        }, 2000);
                      }}
                    >
                      CONFIRM
                    </Button>
                    <Button variant="ghost" onClick={onClose}>
                      {tableRequestLoading || tableRequestFinished
                        ? 'Close'
                        : 'Cancel'}
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            </Flex>
          </Flex>
          <Box
            w="100vw"
            alignContent={'center'}
            justifyContent={'center'}
            mb="100px"
            bgColor={'#000000'}
          >
            <VStack>
              <Text
                fontSize="3xl"
                bgGradient="linear(to-l, #FF0080, #7928CA, #FF0080)"
                bgClip="text"
                fontWeight="extrabold"
              >
                SHARE THE FUN
              </Text>

              <a
                w={'100vw'}
                href={`sms://?&body=Hey%21%20I%20am%20going%20to%20this%20party%20in%20LA%20on%20Sat%203%2F16%20at%20Six%20Flags%2C%20come%20with%20me%3A%20https%3A%2F%2Fwww.rasa.world%2Fthrillcitysixflags`}
              >
                <Button mt="10px" variant="outline" borderRadius={'20px'}>
                  INVITE FRIENDS
                </Button>
              </a>
            </VStack>
          </Box>
        </Flex>
        <Modal isOpen={isInfoModalOpen} onClose={onInfoModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Complete Your Profile</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <FormControl isInvalid={validationErrors.email}>
                <FormLabel>Email</FormLabel>
                <Input
                  placeholder="Enter your email"
                  name="email"
                  value={infoModalData.email}
                  onChange={handleInputChange}
                />
                <FormErrorMessage>{validationErrors.email}</FormErrorMessage>
              </FormControl>

              <FormControl mt={4} isInvalid={validationErrors.zipCode}>
                <FormLabel>Zip Code</FormLabel>
                <Input
                  placeholder="Enter your zip code"
                  name="zipCode"
                  value={infoModalData.zipCode}
                  onChange={handleInputChange}
                />
                <FormErrorMessage>{validationErrors.zipCode}</FormErrorMessage>
              </FormControl>

              <FormControl mt={4} isInvalid={validationErrors.igHandle}>
                <FormLabel>Instagram Handle</FormLabel>
                <Input
                  placeholder="Enter your Instagram handle"
                  name="igHandle"
                  value={infoModalData.igHandle}
                  onChange={handleInputChange}
                />
                <FormErrorMessage>{validationErrors.igHandle}</FormErrorMessage>
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={handleSubmitInfo}>
                Save
              </Button>
              <Button onClick={onInfoModalClose}>Cancel</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </VStack>
    </Box>
  );
}
