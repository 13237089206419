import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import Slider from 'react-slick';
import QRCode from 'qrcode.react';
/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react';

import { stytchUser, useStytchUser} from '@stytch/react';

const movingBorder = keyframes`
  0% {
    border-image-source: linear-gradient(90deg, white, black);
  }
  100% {
    border-image-source: linear-gradient(90deg, black, white);
  }
`;
// Define individual animations for each border segment
const topToRight = keyframes`
  0%, 100% {
    top: 0;
    left: 0;
  }
  100% {
    top: 0;
    left: 100%;
  }
`;

const rightToBottom = keyframes`
  0%, 100% {
    top: 0;
    right: 0;
  }
  100% {
    bottom: 0;
    right: 0;
  }
`;

const bottomToLeft = keyframes`
  0%, 100% {
    bottom: 0;
    right: 0;
  }
  100% {
    bottom: 0;
    left: 0;
  }
`;

const leftToTop = keyframes`
  0%, 100% {
    bottom: 0;
    left: 0;
  }
  100% {
    top: 0;
    left: 0;
  }
`;

export function TicketsCarousel({ tickets }) {
  console.log(tickets)
  const stytchUser = useStytchUser();
  const settings = {
    speed: 500,
    dots: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 2,
    style: { width: '100%' },
    adaptiveHeight: true,
    alignItems: 'center',
    centerMode: true,
  };

  return (
    <Box width="100vw" overflow="hidden">
      <Slider {...settings}>
        {tickets?.map(ticketObj => (
          <Box
            p={5}
            boxShadow="lg"
            rounded="md"
            key={ticketObj._id}
            alignContent={'center'}
            justifyContent={'center'}
            display={'flex'}
            bgColor={'white'}
          >
            {/* Animated QR code border */}
            <Box
              alignContent={'center'}
              justifyContent={'center'}
              display={'flex'}
            >
              <QRCode value={`https://rasa-platform-api.fly.dev/api/events/eventTicketCheckin/${stytchUser?.user?.user_id}/reserve/${ticketObj._id}`} />
            </Box>

            <Box
              mt={4}
              alignContent={'center'}
              justifyContent={'center'}
              display={'flex'}
            >
              <Text textColor={"black"}>{ticketObj.ticket.ticketName.toUpperCase()}</Text>
            </Box>
            {tickets.length > 1 && (
              <Text
                mt={4}
                alignContent={'center'}
                justifyContent={'center'}
                display={'flex'}
                fontSize={'sm'}
                color={'black'}
              >
                SWIPE TO VIEW MORE
              </Text>
            )}
          </Box>
        ))}
      </Slider>
    </Box>
  );
}
