import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import { ColorModeScript } from '@chakra-ui/react';

import { QueryClient, QueryClientProvider } from 'react-query';
import {
  ChakraProvider,
  Box,
  extendTheme,
  useColorMode,
} from '@chakra-ui/react';

import { StytchProvider } from '@stytch/react';
import { StytchUIClient } from '@stytch/vanilla-js';

import { SignUpPage } from './signup';
import { SignUpInvitedGuestListPage } from './signupInvitedGuestList';
import { ConfirmSignUp } from './confirm-signup';
import { LoginPage } from './login';
import { PurchaseTickets } from './purchase-tickets';
import { InviteFriends } from './inviteFriends';
import { InvitedGuestPage } from './invitedGuest';
import { LoginGuestList } from './loginGuestList';
import { IntoToEvent } from './introToEvent';
import { LoginInvitedGuestPage } from './loginInvitedGuest';
import { NEXT_STYTCH_PUBLIC_KEY } from './constants';
import { SelectEventPage } from './select-event';
import { SpecificEvent } from './specificEvent';
import { PurchaseTicketsSpecific } from './purchaseTicketsSpecificEvent';

import { GetMissingInfoPage } from './get-missing-info';
import ConfirmPresale from './confirm-presale';
import CheckoutConfirmation from './checkout-confirmation';
import TermsAndConditions from './terms-and-conditions';
import { SignUpPagePublicCheckout } from './publicCheckoutSignups';
import { LoginPageCheckout } from './login-checkout';
import { LoginBaoli } from './login-baoli';
import { SPEvent } from './spEvent';
import { SignUpPageBaoli } from './signup-baoli';
import { LoginBaoliSlug } from './login-baoli-slug';
import { LoginFrieze } from './login-frieze';
import { SignUpPageFrieze } from './signup-frieze';
import TalaEventPage from './tala-event';
import LogRocket from 'logrocket';

import PublicEventCheckoutPage from './public-checkout';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

LogRocket.init('jvlwm0/rasafyi');

const theme = extendTheme({
  initialColorMode: 'light',
  useSystemColorMode: false,
  styles: {
    global: {
      body: {
        bg: '#0A0323',
      },
    },
  },
});

function App() {
  const stytch = new StytchUIClient(NEXT_STYTCH_PUBLIC_KEY);

  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <StytchProvider stytch={stytch}>
        <ChakraProvider theme={theme}>
          <Box
            h="100vh"
            w="100vw"
            overflowX="hidden" // prevent scrolling
          >
            <Router>
              <Routes>
                <Route path="/" element={<Navigate to="/login" />} />
                <Route path="/signup" element={<SignUpPage />} />
                <Route path="/signup/:slug" element={<SignUpPage />} />
                <Route
                  path="/signupInvitedGuestList"
                  element={<SignUpInvitedGuestListPage />}
                />
                <Route path="/confirmSignUp" element={<ConfirmSignUp />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/:slug" element={<LoginPage />} />
                <Route path="/groovePublicTickets" element={<PublicEventCheckoutPage />} />
                <Route path="/checkoutConfirmation" element={<CheckoutConfirmation />} />
                <Route
                  path="/signUpInvitedGuest/:slug"
                  element={<InvitedGuestPage />}
                />
                <Route path="/introToEvent/:slug" element={<IntoToEvent />} />
                <Route path="/loginGuestList" element={<LoginGuestList />} />
                <Route
                  path="/termsAndConditions"
                  element={<TermsAndConditions />}
                />
                <Route path="/spEvent/:slug" element={<SPEvent />} />

                <Route
                  path="/specificEvent/:slug"
                  element={<SpecificEvent />}
                />
                <Route
                  path="/purchaseTicketsSpecific/:slug"
                  element={<PurchaseTicketsSpecific />}
                />
              </Routes>
            </Router>
          </Box>
        </ChakraProvider>
      </StytchProvider>
    </QueryClientProvider>
  );
}

export default App;
