import React, { useState } from 'react';
import {
  VStack,
  Input,
  Button,
  Box,
  Text,
  HStack,
  useToast,
} from '@chakra-ui/react';
import { useStytchUser, useStytch } from '@stytch/react';

import { NEXT_PUBLIC_DEPLOY_URL } from '../../constants';

const SignupForm = ({ onContinue }) => {
  const params = new URLSearchParams(window.location.search);
  const promoterID = params.get('special');
  const stytchUser = useStytchUser();

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    igHandle: '',
    zipCode: '',
    igHandle: '',
    phone: stytchUser?.user?.phone_numbers[0]?.phone_number,
    promoterLinkID: promoterID,
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const handleChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    let isValid = true;
    let newErrors = {};

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async () => {
    // Set loading state
    // Also prevents multiple submissions
    setLoading(true);

    if (!validateForm()) {
      setLoading(false);
      return;
    }

    try {
      // Mock API call
      const response = await fetch(
        `${NEXT_PUBLIC_DEPLOY_URL}/api/guest/${stytchUser?.user?.user_id}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        }
      );

      console.log(response)

      if (response.ok) {
        setLoading(false);
        onContinue();
        toast({
          title: 'Success!',
          description: 'You have been registered.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        // Handle successful response
      } else {
        setLoading(false);
        toast({
          title: 'Error!',
          description: 'Something went wrong.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        // Handle error response
      }
    } catch (error) {
      setLoading(false);
      toast({
        title: 'Error!',
        description: 'Something went wrong.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      // Handle network error
    }
  };

  return (
    <VStack spacing={4} w="full" alignItems="center">
      <Box bg="purple" rounded="full" px={4} py={2}>
        <HStack>
          <Text fontSize="2xl">🎉</Text>
          <Text fontSize="xs" fontWeight="bold">
            Looks like it's your first time here. Please enter some details
            about yourself.
          </Text>
        </HStack>
      </Box>
      <VStack spacing={4} w="full" alignItems="center">
        <Box marginTop="2rem">
          <Input
            borderRadius="15px"
            background="#0A010A"
            color="white"
            border="1px solid #282536"
            textAlign="left"
            placeholder="First Name"
            size="lg"
            focusBorderColor="#5555FF"
            width="82vw"
            mb="1.5rem"
            type="text"
            name="firstName"
            onChange={handleChange}
            value={formData.firstName}
            isInvalid={errors.firstName}
          />
          <Input
            borderRadius="15px"
            background="#0A010A"
            color="white"
            border="1px solid #282536"
            textAlign="left"
            placeholder="Last Name"
            size="lg"
            focusBorderColor="#5555FF"
            width="82vw"
            type="text"
            name="lastName"
            mb="1.5rem"
            onChange={handleChange}
            value={formData.lastName}
            isInvalid={errors.lastName}
          />
          <Input
            borderRadius="15px"
            background="#0A010A"
            color="white"
            border="1px solid #282536"
            textAlign="left"
            placeholder="Email"
            size="lg"
            focusBorderColor="#5555FF"
            width="82vw"
            type="email"
            name="email"
            mb="1.5rem"
            onChange={handleChange}
            value={formData.email}
            isInvalid={errors.email}
          />
          <Input
            borderRadius="15px"
            background="#0A010A"
            color="white"
            border="1px solid #282536"
            textAlign="left"
            placeholder="IG Handle"
            size="lg"
            focusBorderColor="#5555FF"
            width="82vw"
            type="text"
            name="igHandle"
            mb="1.5rem"
            onChange={handleChange}
            value={formData.igHandle}
            isInvalid={errors.igHandle}
          />
          <Input
            borderRadius="15px"
            background="#0A010A"
            color="white"
            border="1px solid #282536"
            textAlign="left"
            placeholder="Zip Code"
            size="lg"
            focusBorderColor="#5555FF"
            width="82vw"
            type="text"
            name="zipCode"
            mb="1.5rem"
            onChange={handleChange}
            value={formData.zipCode}
            isInvalid={errors.zipCode}
          />
        </Box>
      </VStack>
      <Button colorScheme="blue" variant="solid" w="full" onClick={handleSubmit}>
        CONTINUE
      </Button>
    </VStack>
  );
};

export default SignupForm;
