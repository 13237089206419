import { Button, Input, InputGroup, InputLeftAddon } from '@chakra-ui/react';
import React from 'react';

import { CountrySelector, usePhoneInput } from 'react-international-phone';

interface ChakraPhoneProps {
  value: string;
  onChange: (phone: string) => void;
}

export const ChakraPhone: React.FC<ChakraPhoneProps> = ({
  value,
  onChange,
}) => {
  const phoneInput = usePhoneInput({
    defaultCountry: 'us',
    inputValue: value, // Updated from value to inputValue
    onChange: (phone, data) => {
      // Updated second argument to object
      onChange(phone); // Use phone for E.164 format
    },
    disableDialCodeAndPrefix: true,
  });

  if (phoneInput.country.iso2 === 'fr') {
    phoneInput.inputValue = phoneInput.inputValue.replace(/^0+/, '');
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <CountrySelector
        className='z-1000'
        selectedCountry={phoneInput.country.iso2} // Updated to use parsed country object
        onSelect={country => phoneInput.setCountry(country.iso2)}
        renderButtonWrapper={({ children, rootProps }) => (
          <Button
            {...rootProps}
            border="1px solid #282536"
            p="16px"
            mr="8px"
            background="#0A010A"
            borderRadius="1rem"
            height="3rem"
          >
            {children}
          </Button>
        )}
      />
      <InputGroup>
        <InputLeftAddon
          children={'+' + phoneInput.country.dialCode}
          background="#0A010A"
          border="1px solid #282536"
          borderTopLeftRadius="1rem"
          borderBottomLeftRadius="1rem"
          height="3rem"
        />
        <Input
          placeholder="Mobile number"
          type="tel"
          color="primary"
          background="#0A010A"
          value={phoneInput.inputValue} // Updated from phone to inputValue
          onChange={phoneInput.handlePhoneValueChange}
          ref={phoneInput.inputRef}
          autoComplete="tel-national"
          borderRadius="1rem"
          height="3rem"
          border="1px solid #282536"
          focusBorderColor="#5555FF"
        />
      </InputGroup>
    </div>
  );
};
