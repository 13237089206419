import React from 'react';
import { useParams } from 'react-router-dom';
import { LoadingScreen } from '../loader';
import { useEventBySlug } from '../api';
import { Box, Button } from '@chakra-ui/react';
import { EventPage } from './eventDetails';
import { TicketsPage } from './tickets';
import { RewardsPage } from './rewards';
import { ProfilePage } from './profile';
import CustomTabs from './tablist';

export function SpecificEvent() {
  // Get the event-slug from the URL
  const { slug } = useParams();
  const params = new URLSearchParams(window.location.search);
  const redirectToTicket = params.get('redirectToTicket');
  const { data: event, isLoading } = useEventBySlug(slug);
  const [tabState, setTabState] = React.useState(redirectToTicket ? 1 : 0);
  return (
    // If the event is loading, show the loading screen
    isLoading ? (
      <LoadingScreen />
    ) : (
      <Box
        display="flex"
        flexDirection="column"
        height="100vh"
        width="100vw"
        alignItems="center"
        bgColor="#000000"
      >
        <CustomTabs tabState={tabState} setTabState={setTabState} />
        {tabState === 0 && <EventPage event={event} setTabState={setTabState} />}
        {tabState === 1 && <TicketsPage event={event} />}
        {tabState === 2 && <RewardsPage event={event} />}
        {tabState === 3 && <ProfilePage event={event} />}
      </Box>
    )
  );
}
