import React from 'react';
import { useNumberInput } from '@chakra-ui/react';
import { Button, HStack, Input } from '@chakra-ui/react';
export function HookUsage({ quantity, onQuantityChange }) {
  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
    useNumberInput({
      step: 1.0,
      value: quantity,
      min: 0,
      max: 4,
      precision: 0,
      onChange: onQuantityChange, // this will update the shared state
    });

  const inc = getIncrementButtonProps();
  const dec = getDecrementButtonProps();
  const input = getInputProps();

  return (
    <HStack maxW="320px">
      <Button {...dec}>-</Button>
      <Input {...input} />
      <Button {...inc}>+</Button>
    </HStack>
  );
}
