import React, { useState, useEffect } from 'react';
import {
  CardElement,
  PaymentElement,
  ExpressCheckoutElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { Button, VStack, useToast, Flex, Text, Divider } from '@chakra-ui/react';

export default function BuyTicketCardInfo({
  tickets,
  eventDetails,
  ticketsSelected,
  paymentIntentId,
}) {
  const stripe = useStripe();
  const elements = useElements();
  const toast = useToast();
  const [visibility, setVisibility] = useState(false);

  console.log(paymentIntentId)

  const onReady = ({ availablePaymentMethods }) => {
    console.log(availablePaymentMethods);
    if (!availablePaymentMethods) {
      // No buttons will show
    } else {
      // Optional: Animate in the Element
      setVisibility(true);
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // add the payment intent id to the return url
        return_url: window.location.origin + '/checkoutConfirmation',
      },
    });

    if (result.error) {
      console.log(result.error.message);
      toast({
        title: 'Payment Error',
        description: result.error.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handleExpressCheckout = async () => {
    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // add the payment intent id to the return url
        return_url: window.location.origin + '/checkoutConfirmation',
      },
    });

    if (result.error) {
      console.log(result.error.message);
      toast({
        title: 'Payment Error',
        description: result.error.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-6">
      {/* Express Checkout Section */}
      {visibility && (
        <div>
          <Text>Express Checkout</Text>
          <Text>
            Fast and secure checkout with your preferred payment method.
          </Text>
        </div>
      )}
      <div className="mt-4">
        <ExpressCheckoutElement
          onConfirm={handleExpressCheckout}
          onReady={onReady}
        />
      </div>

      <Divider />

      {/* Credit Card Checkout Section */}

      <PaymentElement />

      <Button
        colorScheme="yellow"
        size="md"
        type="submit"
        disabled={!stripe}
        w="100%"
      >
        Pay
      </Button>
    </form>
  );
}
