import { useState, useEffect } from 'react';
import { NEXT_PUBLIC_DEPLOY_URL } from '../constants';
import { useQuery } from 'react-query';

export const createCheckoutSession = async (
  ticketItems,
  user_id,
  event_slug,
  discount_code
) => {
  console.log(
    JSON.stringify({
      items: ticketItems,
      eventSlug: event_slug,
      user_id: user_id,
      discount_code: discount_code,
    })
  );
  const response = await fetch(
    `${NEXT_PUBLIC_DEPLOY_URL}/api/checkout/create-checkout-session`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        items: ticketItems,
        eventSlug: event_slug,
        user_id: user_id,
        discount_code: discount_code,
      }),
    }
  ).catch(err => {
    console.log(err);
  });

  const data = await response.json();
  return data;
};

const fetchEventBySlug = async slug => {
  const response = await fetch(
    `${NEXT_PUBLIC_DEPLOY_URL}/api/events/get/slug/${slug}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  return data;
};

export const useEventBySlug = slug => {
  return useQuery([slug], () => fetchEventBySlug(slug));
};

const fetchActiveEvents = async (): any => {
  const response = await fetch(
    `${NEXT_PUBLIC_DEPLOY_URL}/api/events/activeEvents`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  return data;
};

export const useActiveEvents = () => {
  return useQuery(['active_events'], () => fetchActiveEvents());
};

export const createTableRequest = async (auth_service_id, slug) => {
  const response = await fetch(
    `${NEXT_PUBLIC_DEPLOY_URL}/api/guest/tableRequest/${auth_service_id}/${slug}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  const data = await response.json();
  return data;
};

export const claimSharedTicket = async (
  auth_service_id,
  from_guest_id,
  ticket_id
) => {
  const response = await fetch(
    `${NEXT_PUBLIC_DEPLOY_URL}/api/guestss/claimSharedTicket/${auth_service_id}/${from_guest_id}/${ticket_id}`
  );

  const data = await response.json();
  return data;
};

export const claimFreeTicket = async auth_service_id => {
  const response = await fetch(
    `${NEXT_PUBLIC_DEPLOY_URL}/api/claimFreeTicket/${auth_service_id}`
  );

  const data = await response.json();
  return data;
};

export const claimTicket = async (auth_service_id, ticket_id) => {
  const response = await fetch(
    `${NEXT_PUBLIC_DEPLOY_URL}/api/guestss/claimTicket/${auth_service_id}/${ticket_id}`
  );

  const data = await response.json();
  return data;
};
export const updateSplashStatus = async auth_service_id => {
  const response = await fetch(
    `${NEXT_PUBLIC_DEPLOY_URL}/api/guest/updateSplashStatus/${auth_service_id}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  const data = await response.json();
  return data;
};

export const createGuest = async (auth_service_id, guestData) => {
  const response = await fetch(
    `${NEXT_PUBLIC_DEPLOY_URL}/api/guest/${auth_service_id}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(guestData),
    }
  );

  const data = await response.json();
  return data;
};

export const createApprovedGuest = async guestData => {
  const response = await fetch(
    `${NEXT_PUBLIC_DEPLOY_URL}/api/guest/approved/approveGuest`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(guestData),
    }
  );

  const data = await response.json();
  return data;
};

export const createApprovedCovenGuest = async guestData => {
  const response = await fetch(
    `${NEXT_PUBLIC_DEPLOY_URL}/api/guest/approved/approveGuestCoven`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(guestData),
    }
  );

  const data = await response.json();
  return data;
};

export const createApprovedGuestTicket = async guestData => {
  const response = await fetch(
    `${NEXT_PUBLIC_DEPLOY_URL}/api/guest/approved/approveGuestTicket`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(guestData),
    }
  );

  const data = await response.json();
  return data;
};

export const getGuests = async (authServiceId, phoneNumber) => {
  const response = await fetch(
    `${NEXT_PUBLIC_DEPLOY_URL}/api/guests?authServiceId=${authServiceId}&phoneNumber=${phoneNumber}`
  );
  const data = await response.json();
  return data;
};

export const getGuest = async authServiceId => {
  const response = await fetch(
    `${NEXT_PUBLIC_DEPLOY_URL}/api/guest?authServiceId=${authServiceId}`
  );
  const data = await response.json();
  return data;
};

export const getInviteLinkStatus = async inviteId => {
  const response = await fetch(
    `${NEXT_PUBLIC_DEPLOY_URL}/api/inviteCheck//${inviteId}`
  );
  const data = await response.json();
  return data;
};

export const checkValidInviteId = async inviteId => {
  const response = await fetch(
    `${NEXT_PUBLIC_DEPLOY_URL}/api/inviteCheck/${inviteId}`
  );
  const data = await response.json();
  return data;
};

export const getCouponCode = async code => {
  const response = await fetch(`${NEXT_PUBLIC_DEPLOY_URL}/api/coupon/${code}`);
  const data = await response.json();
  return data;
};

export const useCheckTicketId = ticketID => {
  const [valid, setValid] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchValid = async () => {
      if (ticketID == null) {
        return;
      }

      try {
        const response = await fetch(
          `${NEXT_PUBLIC_DEPLOY_URL}/api/guest/checkTicketID/${ticketID}`
        );

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setLoading(false);
        setValid(data);
      } catch (err) {
        setLoading(false);
        setError(err.message);
      }
    };

    fetchValid();
  }, [ticketID]); // run again when inviteId changes

  return { valid, loading, error };
};

export const useCheckValidInviteId = inviteId => {
  const [valid, setValid] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchValid = async () => {
      if (inviteId == null) {
        return;
      }

      try {
        const response = await fetch(
          `${NEXT_PUBLIC_DEPLOY_URL}/api/inviteCheck/${inviteId}`
        );

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setLoading(false);
        setValid(data);
      } catch (err) {
        setLoading(false);
        setError(err.message);
      }
    };

    fetchValid();
  }, [inviteId]); // run again when inviteId changes

  return { valid, loading, error };
};

export const useGuest = authServiceId => {
  const [guest, setGuest] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchGuest = async () => {
      if (authServiceId == null || authServiceId === 'UNDEFINED_USER') {
        setLoading(false);
        return;
      }

      try {
        const response = await fetch(
          `${NEXT_PUBLIC_DEPLOY_URL}/api/guest/${authServiceId}`
        );
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();

        setGuest(data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };
    setLoading(true);
    fetchGuest();
  }, [authServiceId]); // run again when authServiceId changes

  return { guest, error, loading };
};

const checkInviteLink = async (event_slug, invite_code) => {
  const response = await fetch(
    `${NEXT_PUBLIC_DEPLOY_URL}/api/check_guest_list_code/${event_slug}/${invite_code}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  const data = await response.json();
  return data;
};

export const useCheckInviteLink = (event_slug, invite_code) => {
  return useQuery([event_slug, invite_code], () =>
    checkInviteLink(event_slug, invite_code)
  );
};

export function place_guest_on_guest_list(input) {
  return fetch(
    `${NEXT_PUBLIC_DEPLOY_URL}/api/placeGuestOnGuestList`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(input),
    }
  );
}
export async function rsvpEvent(auth_service_id, event_slug){
  console.log(`${NEXT_PUBLIC_DEPLOY_URL}/api/events/rsvpEvent/${event_slug}/${auth_service_id}`)
  const response = await fetch(
    `${NEXT_PUBLIC_DEPLOY_URL}/api/events/rsvpEvent/${event_slug}/${auth_service_id}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: ""
    }
  ).catch(err => {
    console.log(err);
  });

  const data = await response.json();
  console.log(data)
  return data;
}

const fetchEventLocations = async () => {
  const response = await fetch(
    `${NEXT_PUBLIC_DEPLOY_URL}/api/events/eventLocationHub`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  const data = await response.json();
  return data;
};

export const useFetchEventLocations = () => {
  return useQuery([], () =>
    fetchEventLocations()
  );
};

export const checkGuestListInvitationLinkIsValid = async (event_slug, invite_code) => {
  const response = await fetch(
    `${NEXT_PUBLIC_DEPLOY_URL}/api/events/guestlist/checkInvite/${event_slug}/${invite_code}`,
  );

  const data = await response.json();
  console.log(data)
  return data;
};

export const useCheckGuestListInvitationLinkIsValid = (event_slug, invite_code) => {
  return useQuery([event_slug, invite_code], () =>
    checkGuestListInvitationLinkIsValid(event_slug, invite_code)
  );
};

export const createNewUserWithGuestlist = async ({auth_service_id, event_slug, invite_id, signUpData}) => {
  const response = await fetch(
    `${NEXT_PUBLIC_DEPLOY_URL}/api/events/guestlist/createNewUser/${event_slug}/${invite_id}/${auth_service_id}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(signUpData)
    }
  );

  const data = await response.json();
  console.log(data)
  return data;
}

export const addGuestListExistingUser = async ({auth_service_id, event_slug, invite_code}) => {
  const response = await fetch(
    `${NEXT_PUBLIC_DEPLOY_URL}/api/events/guestlist/addGuestListExistingUser/${event_slug}/${invite_code}/${auth_service_id}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },

    }
  );

  const data = await response.json();
  console.log(data)
  return data;
}


export const fetchAppleWalletPass = async (guest_id) => {
  const response = await fetch(
    `${NEXT_PUBLIC_DEPLOY_URL}/api/events/issueNFCPassIOS/${guest_id}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  const data = await response.json();
  return data;
}

export const issueFreeTicket = async (event_slug, auth_service_id, ticket_id) => {
  const response = await fetch(
    `${NEXT_PUBLIC_DEPLOY_URL}/api/events/issueFreeTicket/${event_slug}/${auth_service_id}/${ticket_id}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  const data = await response.json();
  return data;
}

const fetchPrometerByID = async (promoter_id) => {
  const response = await fetch(
    `${NEXT_PUBLIC_DEPLOY_URL}/api/promoter/${promoter_id}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  const data = await response.json();
  return data;
}

export const useFetchPromoterByID = (promoter_id) => {
  return useQuery([promoter_id], () =>
    fetchPrometerByID(promoter_id)
  );
};

export const createPaymentIntent = async (
  auth_service_id,
  event_slug,
  eventTickets,
  ticketsSelected,
  discountCode,
  promoterID,
) => {
  const ticketsIntendedToPurchase = [];

  // Loop through the selected tickets
  Object.keys(ticketsSelected).forEach((ticket_id) => {
    const quantity = ticketsSelected[ticket_id];
    console.log(quantity);

    // Find the ticket in the eventTickets array
    const ticket = eventTickets.find((ticket) =>
      ticket.tiers.some((tier) => tier._id === ticket_id)
    );

    if (ticket) {
      // Calculate the total price for this ticket based on quantity
      const totalPrice = ticket.tiers.find((tier) => tier._id === ticket_id)
        .tierPrice * quantity;

      // Store the ticket information with quantity and total price
      ticketsIntendedToPurchase.push({
        ticket_id: ticket._id,
        tier_id: ticket_id,
        quantity: quantity,
      });
    }
  });

  const response = await fetch(`${NEXT_PUBLIC_DEPLOY_URL}/api/checkout/create-payment-intent`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      auth_service_id,
      eventSlug: event_slug,
      ticketsIntendedToPurchase,
      discount_code: discountCode,
      promoter_id: promoterID,
    }),
  });

  const data = await response.json();
  console.log(data);
  return data;
};

const fetchTicketsByPaymentID = async (paymentID) => {
  const response = await fetch(
    `${NEXT_PUBLIC_DEPLOY_URL}/api/publicEventTicketsByPaymentIntentID/${paymentID}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  const data = await response.json();
  return data;
}

export const useFetchTicketsByPaymentID = (paymentID) => {
  return useQuery([paymentID], () =>
    fetchTicketsByPaymentID(paymentID)
  );
};

export const createTableRequestPublic = async ({auth_service_id, event_slug}) => {
  const response = await fetch(
    `${NEXT_PUBLIC_DEPLOY_URL}/api/guest/tableRequest/${auth_service_id}/${event_slug}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  const data = await response.json();
  return data;
}

export const createTableRequestPublicInvalidUser = async ({first_name, last_name, phone_number, event_slug}) => {
  const response = await fetch(
    `${NEXT_PUBLIC_DEPLOY_URL}/api/guest/tableRequestInvalidUser/${first_name}/${last_name}/${phone_number}/${event_slug}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  const data = await response.json();
  return data;
}

export const createGuestSpringThrill = async (auth_service_id, guestData) => {
  const response = await fetch(
    `${NEXT_PUBLIC_DEPLOY_URL}/api/create_default_guest/${auth_service_id}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(guestData),
    }
  );

  const data = await response.json();
  return data;
};

export const updateGuestAfterSpringThrill = async (auth_service_id, guestData) => {
  const response = await fetch(
    `${NEXT_PUBLIC_DEPLOY_URL}/api/update_guest/${auth_service_id}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(guestData),
    }
  );

  const data = await response.json();
  return data;
};

export const checkForFreeTicket = async (auth_service_id, slug) => {
  const body = {
    "auth_service_id": auth_service_id,
    "issuer": slug
  }
  const response = await fetch(
    `${NEXT_PUBLIC_DEPLOY_URL}/api/guest/autoApprove/ibiza/${slug}/${auth_service_id}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    }
  );

  const data = await response.json();

  console.log(data);
  return data;
}
export const useCheckForFreeTicket = (auth_service_id, slug) => {
  return useQuery([auth_service_id, slug], () =>
    checkForFreeTicket(auth_service_id, slug)
  );
}