import React from 'react';

import { Box, Button, Text, VStack, useDisclosure } from '@chakra-ui/react';

import { useStytchSession } from '@stytch/react';

import EventOverview from '../../specificEvent/components/EventCard';
import VenueDetails from '../../specificEvent/components/VenueDetails';
import EventDetails from '../../specificEvent/components/EventAboutDescription';
import RequestTableModal from './RequestTableModal';
import Banner from './BannerComponent';

function findLowestActiveTierPrice(tickets) {
  const currentDate = new Date();
  let lowestPrice = null;

  tickets.forEach(ticket => {
    ticket.tiers.forEach(tier => {
      const tierStartDate = new Date(tier.startDate);
      if (tierStartDate <= currentDate) {
        // Check if the tier is active
        if (lowestPrice === null || tier.tierPrice < lowestPrice) {
          lowestPrice = tier.tierPrice;
        }
      }
    });
  });

  return lowestPrice;
}

export default function EventDetailsPublic({
  event,
  promoter,
  setPageState,
  guest,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const stytchSess = useStytchSession();

  const calculateTimeLeft = () => {
    // Start date is March 16th 2024 9:00 PM PST in utc
    const difference = +new Date('07/19/2024 18:00:00') - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = React.useState(calculateTimeLeft());

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const formatTime = time => (time < 10 ? `0${time}` : time);

  console.log(promoter);

  return (
    <Box
      p={{ base: 0, md: 100 }}
      bg={'black'}
      display={'flex'}
      width={'100%'} // Ensure the Box takes full width to distribute items evenly
      flexDirection={{ base: 'column', md: 'row' }} // Stack items vertically on small screens, horizontally on larger screens
    >
      {/* Detail view content */}
      <Box>
        {promoter.discountAmount > 0 && (
          <Banner
            text={`You have been invited by ${promoter.firstName} ${
              promoter.lastName
            }, ${promoter.discountAmount * 100}% off your ticket!`}
          />
        )}
        <EventOverview
          flier={event.eventImageURL}
          title={event.eventDetails.eventName}
          location={event.eventDetails.eventLocation}
          date={event.eventDetails.eventStartDate}
        />
      </Box>
      <Box maxW={'100vw'}>
        <EventDetails
          description={event.eventDetails.eventAboutDescription}
          presentedBy={'Presented by RASA'}
        />
        <VenueDetails
          venueName={event.eventDetails.eventLocation}
          venueAddress={event.eventDetails.eventAddress}
          venueCity={event.eventDetails.eventCity}
          venueState={event.eventDetails.eventState}
          venueZip={event.eventDetails.eventZip}
          venueCountry={event.eventDetails.eventCountry}
          venueDescription={event.eventDetails.eventLocationDescription}
        />

        <Box
          position={{ base: 'fixed', md: 'relative' }}
          bottom={{ base: '0', md: 'auto' }}
          display="flex"
          justifyContent="space-between"
          alignItems="space-between"
          padding="1rem"
          width="100%"
          bg="gray.900"
        >
          <VStack gap={0} alignItems="flex-start">
            <Text fontSize="lg" fontWeight="bold" textColor={"white"}>
              Countdown to GROOVE: {formatTime(timeLeft.days)}:
              {formatTime(timeLeft.hours)}:{formatTime(timeLeft.minutes)}:
              {formatTime(timeLeft.seconds)}
            </Text>
          </VStack>

          <VStack>
            <Button
              fontWeight="bold"
              bg="purple.700"
              textColor={'white'}
              borderRadius="full"
              px="8"
              py="4"
              cursor="pointer"
              zIndex="10"
              width="100%"
              onClick={() => setPageState('checkout')}
            >
              BUY TICKETS
            </Button>
            <Button
              fontWeight="bold"
              bgColor={'purple.700'}
              textColor={'white'}
              borderRadius="full"
              px="8"
              py="4"
              cursor="pointer"
              zIndex="10"
              onClick={onOpen}
            >
              VIP TABLE EXPERIENCE
            </Button>
          </VStack>
          <RequestTableModal
            isOpen={isOpen}
            onClose={onClose}
            event={event}
            promoter={promoter}
            stytchUser={guest}
          />
        </Box>
      </Box>
    </Box>
  );
}
