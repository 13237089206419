export const NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY = 'pk_live_51N5bX2BSQCEjtdpVUHaW8mt0oouzeo78BXJPCvF8l8p9Z597TcdN464bZsyCmx9OXEPuOxvqd65cjVBiddQ1mmTK00sEjakaHK';
//export const NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY = 'pk_test_51N5bX2BSQCEjtdpVyLagAYVxgsiO4x2N0oMcqFZiWgzR3EufIXGwBWX6Yea2iDSbfC6KZcBm7DE6PW14W7R8zfW200n7s1FPL3';
export const NEXT_PUBLIC_DEPLOY_URL = 'https://rasa-platform-api.fly.dev';
//export const NEXT_PUBLIC_DEPLOY_URL="https://rasa-platform-api-wandering-hill-9167.fly.dev/"
//export const NEXT_PUBLIC_DEPLOY_URL="https://91e12bb87164.ngrok.app"
//export const NEXT_PUBLIC_DEPLOY_URL = 'http://localhost:8110';
export const NEXT_STYTCH_PUBLIC_KEY =
  'public-token-live-96f3a33c-be64-4c00-90c1-bea89ce71971';
export const NEXT_STYTCH_PROJECT_ID =
  'project-live-f60dc30c-17a7-4355-b8ff-9fddba77e444';
export const NEXT_STRIPE_VIP_PRODUCT_ID = 'price_1NFPFxBSQCEjtdpVzfJoBfiw';
export const NEXT_STRIPE_GA_PRODUCT_ID = 'price_1NCotxBSQCEjtdpVcYHnUE9b';

export const Dummy = () => null;

export default Dummy;
