export default function AnnoucmentIcon({}) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          stroke="currentColor"
          stroke-linecap="square"
          d="M8.5 14.5h-3v-5h5m-2 5v4m0-4h2m0 0 7 4v-13l-7 4m0 5v-5m9 1v3"
        ></path>
      </svg>
    );
  }
  
  AnnoucmentIcon.propTypes = {};
  