import React, { useState, useEffect } from 'react';
import { VStack, Text, Button } from '@chakra-ui/react';
import OtpInput from 'react18-input-otp';

const OtpVerification = ({ otp, setOtp, onVerifyCode, onResendCode }) => {
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const [countdown, setCountdown] = useState(30);

  useEffect(() => {
    let interval;
    if (isResendDisabled) {
      interval = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [isResendDisabled]);

  useEffect(() => {
    if (countdown === 0) {
      setIsResendDisabled(false);
      setCountdown(30);
    }
  }, [countdown]);

  const handleResendClick = () => {
    onResendCode();
    setIsResendDisabled(true);
  };

  return (
    <VStack spacing={4} w="full" alignItems="center">
      <Text fontSize="3xl" color="white">
        Enter your code
      </Text>
      <Text color="gray.400" fontSize="sm" textAlign="center">
        We sent a code to your phone number, please enter it below.
      </Text>
      <OtpInput
        inputStyle={{
          width: '100%',
          height: '50px',
          fontSize: '5vw',
          borderRadius: '12px',
          border: '1px #282536 solid',
          color: '#EEEDF2',
          backgroundColor: '#0A010A',
          margin: '0px',
          textAlign: 'center',
          fontWeight: 'bold',
        }}
        focusStyle={{
          outline: 'none',
          border: '1px #5555FF solid',
          boxShadow: '0px 0px 10px #282536',
        }}
        isInputNum={true}
        value={otp}
        onChange={value => setOtp(value)}
        numInputs={6}
        separator={<span>&nbsp;&nbsp;</span>}
      />
      <Button
        colorScheme="blue"
        
        w="full"
        onClick={onVerifyCode}
        isDisabled={otp.length < 6}
      >
        VERIFY CODE
      </Button>
      <Button
        colorScheme="blue"
        variant="outline"
        w="full"
        onClick={handleResendClick}
        isDisabled={isResendDisabled}
      >
        {isResendDisabled ? `Resend in ${countdown}s` : 'RESEND CODE'}
      </Button>
    </VStack>
  );
};

export default OtpVerification;
