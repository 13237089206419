import { Box, Tabs, TabList, Tab, TabPanels, TabPanel, Flex, Text, Circle } from '@chakra-ui/react';

function CustomTabList({ tabState, setTabState }) {
  return (
    <Box  bg="#130213" borderBottom="0.5px solid #1E234D" mb="4" width="100vw">
      <Tabs variant="unstyled" alignItems="space-between" justifyContent="space-between">
        <TabList display="flex" width="100vw" justifyContent="space-around">
          
          <Tab width="25vw" _selected={{ bg: '#151138', borderRadius: '8px', color: '#F7F5F5', textColor: '#F7F5F5' }} onClick={() => setTabState(0)}>
            <Flex direction="row" alignItems="center" p="6px 10px" gap="8px">
              <Text  fontWeight="700" fontSize={['12px', '16px']} lineHeight=" px" letterSpacing="0.3px" textTransform="uppercase" color="#A5B3C8">
                EVENTS
              </Text>
              <Circle size="8px" bg="#FFFFFF" border="1.66667px solid #090B1D" display="none" />
            </Flex>
          </Tab>

          <Tab width="25vw" _selected={{ bg: '#151138', borderRadius: '8px', color: '#A5B3C8', textColor: '#F7F5F5' }} onClick={() => setTabState(1)}>
            <Flex direction="row" alignItems="center" p="6px 10px" gap="8px">
              <Text  fontWeight="500" fontSize={['12px', '16px']} lineHeight="18px" letterSpacing="0.3px" textTransform="uppercase" color="#A5B3C8">
                PASSES
              </Text>
              <Circle size="8px" bg="#FFFFFF" border="1.66667px solid #090B1D" display="none" />
            </Flex>
          </Tab>

          <Tab width="25vw" _selected={{ bg: '#151138', borderRadius: '8px', color: '#A5B3C8' }} onClick={() => setTabState(2)}>
            <Flex direction="row" alignItems="center" p={['6px 10px', '12px 10px' ]} gap="8px">
              <Text  fontWeight="500" fontSize={['12px', '16px']} lineHeight="18px" letterSpacing="0.3px" textTransform="uppercase" color="#A5B3C8">
                FRIENDS
              </Text>
              <Circle size="8px" bg="#FFFFFF" border="1.66667px solid #090B1D" display="none" />
            </Flex>
          </Tab>

          <Tab width="25vw" _selected={{ bg: '#151138', borderRadius: '8px', color: '#A5B3C8' }} onClick={() => setTabState(3)}>
            <Flex direction="row" alignItems="center" p="6px 10px" gap="8px">
              <Text  fontWeight="500" fontSize={['12px', '16px']} lineHeight="18px" letterSpacing="0.3px" textTransform="uppercase" color="#A5B3C8">
                PROFILE
              </Text>
              <Circle size="8px" bg="#FFFFFF" border="1.66667px solid #090B1D" display="none" />
            </Flex>
          </Tab>

          {/* Add more Tab components as required based on your design */}
          
        </TabList>
      </Tabs>
    </Box>
  );
}

export default CustomTabList;