import React, { useState, useEffect } from 'react';
import { Box, HStack, Text, VStack, Button } from '@chakra-ui/react';
import Dropdown from './Dropdown';
import Quantity from './Quantity';

export default function TicketTierContainer({
  ticket,
  setTicketsSelected,
  ticketsSelected,
  promoter,
}) {
  const { tiers } = ticket;
  const [countdown, setCountdown] = useState('');

  // Function to determine the current tier and the next tier's start date
  const getCurrentTierAndNextStartDate = () => {
    if (tiers.length <= 1) {
      return { currentTier: tiers[0], nextStartDate: null }; // Return the only tier and no next start date
    }

    const now = new Date();
    const sortedTiers = [...tiers].sort(
      (a, b) => new Date(a.startDate) - new Date(b.startDate)
    );

    let currentTier = null;
    let nextStartDate = null;

    for (let i = 0; i < sortedTiers.length; i++) {
      const tierStartDate = new Date(sortedTiers[i].startDate);
      if (tierStartDate <= now) {
        currentTier = sortedTiers[i];
        nextStartDate = sortedTiers[i + 1]?.startDate; // Get the start date of the next tier
      } else {
        break;
      }
    }

    return { currentTier, nextStartDate };
  };

  const { currentTier, nextStartDate } = getCurrentTierAndNextStartDate();

  const handleQuantityChange = (tierName, quantity) => {
    setTicketsSelected(prev => {
      // If the quantity is greater than 0, update or add the tier with the new quantity
      if (quantity > 0) {
        return {
          ...prev,
          [tierName]: quantity,
        };
      } else {
        // If the quantity is 0, remove the tier from the state
        const { [tierName]: _, ...rest } = prev;
        return rest;
      }
    });
  };

  // Update the countdown every frame (roughly every 16ms for 60fps), only if there is more than one tier
  useEffect(() => {
    if (!nextStartDate || tiers.length <= 1) return; // Do not set a countdown if there's only one tier
    // Event date is 03/16/2024 6:00 PM PST
    const eventDate = new Date('2024-03-16T18:00:00-08:00');
    const updateCountdown = () => {
      const now = new Date();
      const duration = new Date(eventDate) - now;

      const hours = Math.floor((duration / (1000 * 60 * 60)) % 24)
        .toString()
        .padStart(2, '0');
      const minutes = Math.floor((duration / (1000 * 60)) % 60)
        .toString()
        .padStart(2, '0');
      const seconds = Math.floor((duration / 1000) % 60)
        .toString()
        .padStart(2, '0');
      const milliseconds = Math.floor((duration % 1000) / 10)
        .toString()
        .padStart(2, '0'); // Displaying milliseconds as centiseconds for better readability

      setCountdown(`${hours}:${minutes}:${seconds} UNTIL: SALE ENDS`);
    };

    const interval = setInterval(updateCountdown, 16); // Roughly 60fps
    return () => clearInterval(interval);
  }, [nextStartDate, tiers.length]); // Added tiers.length as a dependency

  return (
    <Box bg="white" p={4} rounded="md" shadow="md" color="gray.400" w="100%">
      <Text fontWeight={500} fontSize="xl" textColor="#0C0A18" mb={2}>
        {ticket.ticketName}
      </Text>
      <Dropdown ticket={ticket} />
      <VStack align="start" spacing={0}>
        {tiers
          .filter(tier => tier.tierName.toLowerCase() !== 'presale')
          .map((tier, index) => (
            <Box
              key={tier.tierName}
              p={tier === currentTier ? 4 : 2}
              borderTopWidth={tier === currentTier ? 4 : 2}
              borderBottomWidth={tier === currentTier ? 4 : 2}
              borderColor={tier === currentTier ? 'blue.500' : 'gray.200'}
              w="full"
              borderTopColor={tier === currentTier ? 'blue.500' : 'gray.200'}
              borderBottomColor={tier === currentTier ? 'blue.500' : 'gray.200'}
            >
              <HStack justify="space-between" w="full">
                <Box>
                  {(tier?.tierName === 'Late Bird' ||
                    tier?.tierName === '2 Birds, 1 Pass (you +1)') && (
                    <Text
                      textColor={'#DF3560'}
                      fontWeight={'bold'}
                      fontSize="12px"
                      lineHeight="18px"
                      letterSpacing="0.3px"
                      textAlign="left"
                    >
                      ⚠️ Tickets Running Low
                    </Text>
                  )}
                  <Text
                    textColor={tier === currentTier ? '#0C0A18' : '#8F8BA3'}
                    fontWeight={tier === currentTier ? 'bold' : 'normal'}
                    fontSize="17px"
                    lineHeight="18px"
                    letterSpacing="0.3px"
                    textAlign="left"
                  >
                    {tier.tierName}
                  </Text>

                  <HStack>
                    <Text
                      textColor={tier === currentTier ? '#0C0A18' : '#8F8BA3'}
                      fontWeight={tier === currentTier ? 'bold' : 'normal'}
                      fontSize="17px"
                      lineHeight="18px"
                      letterSpacing="0.3px"
                      textAlign="left"
                      textDecoration={
                        promoter?.discountAmount > 0 ? 'line-through' : 'none'
                      }
                    >
                      {new Date(tier.startDate) >= new Date() ||
                      currentTier === tier
                        ? '$' + tier.tierPrice.toFixed(0)
                        : null}
                    </Text>

                    {promoter?.discountAmount > 0 && (
                      <Text
                        textColor={'#DF3560'}
                        fontWeight={'bold'}
                        fontSize="17px"
                        lineHeight="18px"
                        letterSpacing="0.3px"
                        textAlign="left"
                      >
                        $
                        {(
                          tier.tierPrice -
                          tier.tierPrice * promoter?.discountAmount
                        ).toFixed(2)}
                      </Text>
                    )}
                  </HStack>
                </Box>
                {(tier === currentTier && (ticket?.sold_out === false || promoter?.promoterLinkID === "AHMADVIP")) && (
                  <Quantity
                    min={0}
                    max={6}
                    value={ticketsSelected[tier._id]}
                    onChange={quantity =>
                      handleQuantityChange(tier._id, quantity)
                    }
                  />
                )}

                {(tier === currentTier && (ticket?.sold_out === true && promoter?.promoterLinkID != "AHMADVIP")) && (
                  <Text
                    textColor={'red'}
                    fontWeight={tier === currentTier ? 'bold' : 'normal'}
                    fontSize="17px"
                    lineHeight="18px"
                    letterSpacing="0.3px"
                  >
                    SOLD OUT
                  </Text>
                )}

                {tier !== currentTier && (
                  <Text
                    textColor={'#8F8BA3'}
                    fontWeight={tier === currentTier ? 'bold' : 'normal'}
                    fontSize="17px"
                    lineHeight="18px"
                    letterSpacing="0.3px"
                  >
                    {/* if it is past the start date, show sold out else show not available */}
                    {new Date(tier.startDate) < new Date() ? (
                      <Text textColor={'#8F8BA3'}>SOLD OUT</Text>
                    ) : (
                      <Text textColor={'#8F8BA3'}>COMING SOON</Text>
                    )}
                  </Text>
                )}
              </HStack>
            </Box>
          ))}
      </VStack>
    </Box>
  );
}
