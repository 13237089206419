import React, { useState, useEffect } from 'react';
import { Button, Input, Tooltip, HStack, Box } from '@chakra-ui/react';

const Quantity = ({ min, max, value, onChange }) => {
  const [currentValue, setCurrentValue] = useState(value || min || 0);
  const [isMaxReached, setIsMaxReached] = useState(false);

  useEffect(() => {
    setCurrentValue(value || min || 0);
  }, [value, min]);

  const increment = () => {
    if (currentValue < max) {
      onChange(currentValue + 1);
      setIsMaxReached(false);
    } else {
      setIsMaxReached(true);
    }
  };

  const decrement = () => {
    if (currentValue > (min || 0)) {
      onChange(currentValue - 1);
        setIsMaxReached(false);
    }
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" >
      <HStack boxShadow="lg" border="1px" borderColor="gray.300" borderRadius="md">
        <Button p="2" w="12" fontSize="xl" bg="gray.200" color="gray.600" _hover={{ bg: 'gray.300' }} borderRadius="md" onClick={decrement}>
          &mdash;
        </Button>
        <Tooltip label={`You can only buy up to ${max} tickets for this event.`} placement="top" isOpen={isMaxReached}>
          <Input w="16" p="2" fontSize="xl" textAlign="center" border="none" value={currentValue} readOnly />
        </Tooltip>
        <Button p="2" w="12" fontSize="xl" bg="gray.200" color="gray.600" _hover={{ bg: 'gray.300' }} borderRadius="md" onClick={increment}>
          &#xff0b;
        </Button>
      </HStack>
    </Box>
  );
};

export default Quantity;
