import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  useDisclosure,
  Text,
  useToast, // Import useToast for feedback
} from '@chakra-ui/react';

import { createTableRequestPublic, createTableRequestPublicInvalidUser } from '../../api';
import { useMutation } from 'react-query';

export default function RequestTableModal({
  isOpen,
  onClose,
  event,
  promoter,
  stytchUser,
}) {
  const [requestCompleted, setRequestCompleted] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const tableRequestMutate = useMutation(stytchUser?._id ? createTableRequestPublic : createTableRequestPublicInvalidUser);
  const toast = useToast(); // Use toast for user feedback

  const isFormValid = () => {
    // Check if form fields are filled out for an invalid user
    return stytchUser?._id || firstName.trim() && lastName.trim() && phoneNumber.trim()
  };

  const handleSubmit = async () => {
    if (!isFormValid()) {
      // Provide feedback to the user if the form is incomplete
      toast({
        title: 'Error',
        description: 'Please fill out all the fields.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (stytchUser?._id) {
      tableRequestMutate.mutateAsync({
        auth_service_id: stytchUser?.auth_service_id,
        event_slug: event.slug,
      });
    } else {
      tableRequestMutate.mutateAsync({
        first_name: firstName,
        last_name: lastName,
        phone_number: phoneNumber,
        event_slug: event.slug,
      });
    }
    setRequestCompleted(true);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'xl'}>
      <ModalOverlay />
      <ModalContent bgColor="purple.900">
        <ModalHeader textColor={"white"}>Request a Table</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {requestCompleted ? (
            <Text color="white">Your table request has been confirmed, we will reach out soon! 🎉</Text>
          ) : (
            <>
              <Text color="white">
                {stytchUser?._id
                  ? 'Request information about table offerings for RASA Thrill City!'
                  : 'Please provide your details to request a table.'}
              </Text>
              {!stytchUser?._id && (
                <>
                  <Input
                    placeholder="First Name"
                    my={2}
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                  <Input
                    placeholder="Last Name"
                    my={2}
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                  <Input
                    placeholder="Phone Number"
                    my={2}
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </>
              )}
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={handleSubmit}
            isLoading={tableRequestMutate.isLoading}
            isDisabled={!isFormValid() || tableRequestMutate.isLoading || requestCompleted}
          >
            {requestCompleted ? 'Request Submitted' : 'Submit Request'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
