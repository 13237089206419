import React from 'react';
import { Box, keyframes, Text } from '@chakra-ui/react';

// Define the animation keyframes
const scrollAnimation = keyframes`
  from { transform: translateX(100%); }
  to { transform: translateX(-100%); }
`;

const Banner = ({ text }) => {
  return (
    <Box
      overflow="hidden" // Hide the overflow so the text outside the box isn't visible
      whiteSpace="nowrap" // Keep text in a single line
      position="relative" // Positioning context for the sliding text
      height="40px" // Set a fixed height for the container
      display="flex"
      alignItems="center" // Center the text vertically
    >
      <Box
        as="span"
        display="inline-block"
        px="2rem" // Add some padding to the left and right 
        animation={`${scrollAnimation} 7s linear infinite`} // Apply the animation
      >
        <Text fontSize="sm" color="gray.500" textAlign="left">
        {text}
        </Text>
      </Box>
    </Box>
  );
};

export default Banner;
