import React from 'react';
import {
  Box,
  VStack,
  Text,
  Flex,
  HStack,
  Spinner,
  Collapse,
  Button,
  Input,
  InputGroup,
  InputRightElement,
  useDisclosure,
} from '@chakra-ui/react';
import { FaCheck } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { useEventBySlug } from '../api';
import { LoadingScreen } from '../loader';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { HookUsage } from './quantityChanger';
import { NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY } from '../constants';
import { loadStripe } from '@stripe/stripe-js';
import { useStytchUser, useStytchSession } from '@stytch/react';
import { useGuest, getCouponCode } from '../api';
import { createCheckoutSession } from '../api';

import { createTableRequest, issueFreeTicket } from '../api';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';

const stripePromise = loadStripe(NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY);

const SEASONAL_PASS_STRIPE_PRODUCT_ID = 'price_1Nv58SBSQCEjtdpVGCBb3l1B';

export function PurchaseTicketsSpecific() {
  const { slug } = useParams();
  const { data: event, isLoading } = useEventBySlug(slug);
  const stytchUser = useStytchUser();
  const stytchSess = useStytchSession();
  const { guest, loading } = useGuest(stytchUser?.user?.user_id);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [tableRequestLoading, setTableRequestLoading] = React.useState(false);
  const [tableRequestFinished, setTableRequestFinished] = React.useState(false);

  const [coupon, setCoupon] = React.useState(null);
  const [validCoupon, setValidCoupon] = React.useState(null);

  const handleCoupon = async e => {
    const couponResp = await getCouponCode(coupon);
    console.log(couponResp);
    setValidCoupon(couponResp);
  };

  React.useEffect(() => {
    // if the session is expired, log the user out
    console.log(stytchSess);
    if (stytchSess?.session == null) {
      window.location.href = '/';
    }
  }, [stytchSess]);

  // State to track which ticket's quantity changer is open (if any)
  const [openTicketId, setOpenTicketId] = React.useState(null);

  const toggleTicket = ticketId => {
    setOpenTicketId(openTicketId === ticketId ? null : ticketId);
  };

  const [ticketQuantities, setTicketQuantities] = React.useState({});

  const updateTicketQuantity = (ticketId, quantity) => {
    setTicketQuantities(prev => ({
      ...prev,
      [ticketId]: quantity,
    }));
  };

  const handleCheckout = async () => {

    // Handle checkout if there is a free coupon
    if (validCoupon?.discount == 1.0) {
      for (const [ticketId, quantity] of Object.entries(ticketQuantities)) {
        if (ticketId === 'seasonalPass') {
          continue
        } else if (quantity > 0) {
          await issueFreeTicket(
            event.slug,
            stytchUser?.user?.user_id,
            ticketId
          );
        }
      }

      window.location.href = `/specificEvent/${event.slug}`;
      return;
    }
    const items = [];
    for (const [ticketId, quantity] of Object.entries(ticketQuantities)) {
      if (ticketId === 'seasonalPass') {
        if (quantity > 0) {
          items.push({
            price_id: SEASONAL_PASS_STRIPE_PRODUCT_ID,
            quantity,
          });
        }
      } else if (quantity > 0) {
        items.push({
          price_id: event.eventTickets.find(t => t._id === ticketId)
            .stripe_price_id,
          quantity,
        });
      }
    }

    const discount = validCoupon?.stripeCouponID
      ? [validCoupon?.stripeCouponID]
      : [];

    const data = await createCheckoutSession(
      items,
      stytchUser?.user?.user_id,
      event.slug,
      discount
    );
    console.log(data);
    const stripe = await stripePromise;
    const result = await stripe.redirectToCheckout({
      sessionId: data.session_id,
    });

    if (result.error) {
      console.log(result.error.message);
    }
  };

  return isLoading || loading ? (
    <LoadingScreen />
  ) : (
    <Box w="100vw">
      <Button
        py="20px"
        variant="outline"
        borderRadius={'20px'}
        onClick={() => (window.location.href = `/specificEvent/${event.slug}`)}
      >
        Back to event
      </Button>
      <VStack spacing="20px">
        <Text
          mt="50px"
          fontStyle="normal"
          fontWeight="500"
          fontSize="39px"
          lineHeight="49px"
          alignItems="center"
          textAlign="center"
          textTransform="uppercase"
        >
          {event.name}
        </Text>
        <Text
          fontStyle="normal"
          fontWeight="500"
          fontSize="16px"
          lineHeight="20px"
          display="flex"
          alignItems="center"
          textAlign="center"
          letterSpacing="0.15px"
          textColor="#A5B0B3"
        >
          {event.eventDetails.eventStartDate} ·{' '}
          {event.eventDetails.eventLocation}
        </Text>
        {event.slug != 'sounds-of-tulum' && (
          <>
            <Flex
              w="358px"
              h="130px"
              p="16px 24px 16px 20px"
              gap="16px"
              bg="#1C001E"
              boxShadow="0px 0px 10px rgba(85, 102, 255, 0.16)"
              borderRadius="16px"
              alignItems="left"
              position="relative"
              id={'seasonalPass'}
              onClick={() => toggleTicket('seasonalPass')}
            >
              <HStack w="100%" justifyContent="space-between">
                <VStack align="start">
                  <Text>Seasonal Pass 🥂🪩</Text>
                  <Text textColor={'lightgray'} fontWeight={'thin'}>
                    Attend our next 4 events and get a free +1 for each
                  </Text>
                  <Text textColor={'lightgray'} fontWeight={'thin'}>
                    $265
                  </Text>
                </VStack>
                <ChevronDownIcon
                  transform={
                    openTicketId === 'seasonalPass' ? 'rotate(180deg)' : null
                  }
                />
              </HStack>
            </Flex>
            <Collapse in={openTicketId === 'seasonalPass'} animateOpacity>
              <HookUsage
                quantity={ticketQuantities['seasonalPass'] || 0}
                onQuantityChange={q => updateTicketQuantity('seasonalPass', q)}
              />
            </Collapse>
            
          </>
        )}
        {event.eventTickets.map(ticket => {
          // Skip the loop if ticketName is "Early Bird"
          if (ticket.ticketName === 'Early Bird' || ticket.ticketName === 'VIP') {
            return null;
          }

          return (
            <>
              <Flex
                key={ticket._id} // assuming each ticket has a unique ID
                w="358px"
                h="130px"
                p="16px 24px 16px 20px"
                gap="16px"
                bg="#1C001E"
                boxShadow="0px 0px 10px rgba(85, 102, 255, 0.16)"
                borderRadius="16px"
                alignItems="left"
                position="relative"
                onClick={() => toggleTicket(ticket._id)}
              >
                <HStack w="100%" justifyContent="space-between">
                  <VStack align="start">
                    <HStack>
                      <Text>
                        {ticket.ticketName}
                      </Text>
                    </HStack>
                    <Text textColor={'lightgray'} fontWeight={'thin'}>
                      ${ticket.ticketPrice}
                    </Text>
                    <Text textColor={'lightgray'} fontWeight={'thin'}>
                      {ticket.ticketDescription}
                    </Text>
                  </VStack>
                  <ChevronDownIcon
                    transform={
                      openTicketId === ticket._id ? 'rotate(180deg)' : null
                    }
                  />
                </HStack>
              </Flex>
              <Collapse in={openTicketId === ticket._id} animateOpacity>
                <HookUsage
                  quantity={ticketQuantities[ticket._id] || 0}
                  onQuantityChange={q => updateTicketQuantity(ticket._id, q)}
                />
              </Collapse>
            </>
          );
        })}

        <Flex
          mb="500px"
          width="100vw" // viewport width
          justifyContent="center" // center horizontally
          alignItems="center" // center vertically
        >
          <Flex
            direction="column"
            alignItems="center"
            w="350px"
            bg="#130213"
            border="0.5px solid #2E092A"
            boxShadow="0px 0px 10px rgba(85, 102, 255, 0.16)"
            borderRadius="16px"
            borderWidth={'1px'}
            gap="16px"
            p="24px 24px 24px 24px"
          >
            <Text
              fontWeight="500"
              fontSize="20px"
              lineHeight="18px"
              letterSpacing="0.3px"
              color="rgba(255, 255, 255, 0.92)"
            >
              Request A Table
            </Text>
            <Text
              w="318px"
              h="38px"
              fontWeight="500"
              fontSize="12px"
              lineHeight="18px"
              letterSpacing="0.3px"
              color="#9795B5"
              mb="16px"
              textAlign={'center'}
            >
              Table booking starts at $1.5k minimum spend for groups of 8
              people. Submit a request and we will reach out soon!
            </Text>
            <Button
              w="119px"
              h="52px"
              boxShadow="0px 7px 80px -12px #5566FF"
              borderRadius="840px"
              color="#FFFFFF"
              fontSize="16px"
              lineHeight="20px"
              letterSpacing="0.15px"
              borderColor={'white'}
              borderWidth={'1px'}
              onClick={onOpen}
            >
              REQUEST
            </Button>
            <VStack
              w="100%"
              align={'left'}
              textAlign={'left'}
              justifyContent={'left'}
              alignContent={'left'}
              display="flex"
              marginLeft={'1vw'}
              size="md"
              width={'80vw'}
            >
              <Text fontSize="xs" color="#6F87A0EB" opacity="0.9">
                COUPON CODE
              </Text>

              <InputGroup size="md">
                <Input
                  placeholder="Enter code"
                  value={coupon}
                  onChange={e => {
                    setCoupon(e.target.value);
                  }}
                />
                <InputRightElement width="4.5rem">
                  <Button
                    h="1.75rem"
                    size="sm"
                    variant="outline"
                    textColor={'#5566FF'}
                    onClick={() => {
                      handleCoupon(coupon);
                    }}
                    isDisabled={validCoupon?._id}
                  >
                    {validCoupon?._id ? (
                      <Text fontSize="xs" color="#72EBB1">
                        <FaCheck />
                      </Text>
                    ) : (
                      <Text fontSize="xs" color="#5566FF">
                        APPLY
                      </Text>
                    )}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </VStack>
            <Modal isOpen={isOpen} onClose={onClose}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>REQUEST TABLE</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  {tableRequestLoading ? (
                    <Flex direction="column" alignItems="center">
                      <Spinner mb="2" />
                      <Text>Processing your request...</Text>
                    </Flex>
                  ) : tableRequestFinished ? (
                    <Text>
                      Your table request has been confirmed, we will reach out
                      soon! 🎉
                    </Text>
                  ) : (
                    <Text>
                      Are you sure you want to request a table for this event?
                    </Text>
                  )}
                </ModalBody>

                <ModalFooter>
                  <Button
                    colorScheme="blue"
                    mr={3}
                    isDisabled={tableRequestLoading || tableRequestFinished}
                    onClick={() => {
                      setTableRequestLoading(true);
                      createTableRequest(guest._id, slug);
                      // Wait 2 seconds to simulate a request
                      setTimeout(() => {
                        setTableRequestLoading(false);
                        setTableRequestFinished(true);
                      }, 2000);
                    }}
                  >
                    CONFIRM
                  </Button>
                  <Button variant="ghost" onClick={onClose}>
                    {tableRequestLoading || tableRequestFinished
                      ? 'Close'
                      : 'Cancel'}
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </Flex>
        </Flex>
      </VStack>
      <Box
        position="fixed"
        width="100%"
        bottom="0"
        bgColor="#130213"
        display="flex"
        justifyContent="center"
        padding="1rem"
        borderRadius={'16px'}
      >
        <VStack w="100%" spacing="20px">
          <VStack spacing="20px" align={'start'}>
            <Flex
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              p={0}
              gap={1}
              w="326px"
              h="18px"
            >
              <Text
                fontStyle="normal"
                fontWeight="500"
                fontSize="12px"
                lineHeight="18px"
                letterSpacing="0.3px"
                textTransform="uppercase"
                color="#A5B0B3"
                m="0 auto"
                w="61px"
                h="18px"
                display="flex"
                alignItems="center"
              >
                Subtotal:
              </Text>
              <Text
                fontStyle="normal"
                fontWeight="500"
                fontSize="12px"
                lineHeight="18px"
                letterSpacing="0.3px"
                textTransform="uppercase"
                color="#FFFFFF"
                m="0 auto"
                w="57px"
                h="18px"
                display="flex"
                alignItems="center"
              >
                $
                {event.eventTickets.reduce(
                  (acc, ticket) =>
                    acc +
                    (ticketQuantities[ticket._id] || 0) * ticket.ticketPrice,
                  0
                ) +
                  (ticketQuantities['seasonalPass'] || 0) * 265}
              </Text>
            </Flex>

            <Flex
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              p={0}
              gap={1}
              w="326px"
              h="18px"
            >
              <Text
                fontStyle="normal"
                fontWeight="500"
                fontSize="12px"
                lineHeight="18px"
                letterSpacing="0.3px"
                textTransform="uppercase"
                color="#A5B0B3"
                m="0 auto"
                h="18px"
                display="flex"
                alignItems="center"
              >
                YOUR COUPON WILL BE APPLIED AT CHECKOUT
              </Text>
            </Flex>
          </VStack>
          <Button
            bgGradient={'linear(to-r, #9763B4, #8978FB)'}
            w={'80%'}
            borderRadius={'20px'}
            onClick={handleCheckout}
          >
            Checkout
          </Button>
        </VStack>
      </Box>
    </Box>
  );
}
