
export default function DoorsOpenIcon({}) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          stroke="currentColor"
          stroke-linecap="square"
          d="M11.5 11.5v1m4-6h2v11h-2m-9-11v11l7 2v-15l-7 2Z"
        ></path>
      </svg>
    );
  };
  
  DoorsOpenIcon.propTypes = {};
  