import React, { useCallback, useState } from 'react';
import { Global, css } from '@emotion/react';
import { HStack, keyframes, useToast } from '@chakra-ui/react';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { motion } from 'framer-motion';
import { PhoneInput } from 'react-international-phone';

import { PhoneNumberUtil } from 'google-libphonenumber';
import { ChakraPhone } from './internationPhoneNumber';

import {
  useColorMode,
  Text,
  Button,
  Box,
  VStack,
  Input,
  Flex,
  PinInputField,
  Divider,
  FormControl,
  FormLabel,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';
import { useStytch, useStytchUser } from '@stytch/react';
import OtpInput from 'react18-input-otp';
import validator from 'validator';
import { RasaLogo } from './logo';
import { useGuest } from '../api';
import { useMutation } from 'react-query';
import { updateSplashStatus, useCheckValidInviteId, useCheckGuestListInvitationLinkIsValid, addGuestListExistingUser } from '../api';
import 'react-international-phone/style.css';
const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = phone => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

export function LoginGuestList() {
  const gradient = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

  // Get token from browser URL
  const params = new URLSearchParams(window.location.search);
  const eventSlug = params.get('event-slug');
  const inviteID = params.get('guestWhoInvited');

  const {
    data: valid,
    loading: loadingInviteIDCheck,
    error,
  } = useCheckGuestListInvitationLinkIsValid(eventSlug, inviteID);

  const addGuestListExistingUserMutation = useMutation(
    addGuestListExistingUser
  );

  const stytchClient = useStytch();
  const stytchUser = useStytchUser();
  const { guest, loading } = useGuest(stytchUser?.user?.user_id);

  const [codeSent, setCodeSent] = useState(false);
  const [code, setCode] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [methodId, setMethodId] = useState('');

  const [isValid, setIsValid] = useState(false);

  const toast = useToast();

  const validatePhoneNumber = input => {
    const phoneNumberPattern =
      /^[+]?[0-9]{1,3}?[-. ]?[0-9]{1,4}[-. ]?[0-9]{1,4}[-. ]?[0-9]{1,9}$/;
    return phoneNumberPattern.test(input);
  };

  const handleInputChange = e => {
    const rawInput = e.target.value;
    const sanitizedInput = rawInput.replace(/[\s()-]/g, ''); // remove spaces, parentheses, and dashes
    console.log(sanitizedInput);
    setPhoneNumber('+1' + sanitizedInput);
    setIsValid(isValidPhoneNumber('+1' + sanitizedInput));
  };

  useColorMode().setColorMode('dark');

  React.useEffect(() => {
    console.log(stytchUser?.user?.user_id);
    console.log(guest);
    console.log(valid);
    if (stytchUser?.user?.user_id && valid) {
      if (loading) return;

      // Logic should be if gust is not found, then we will redirec to singupInvitedGuestList?event-slug=slug&guestWhoInvited=inviteID
      if (guest?.status_code === 404) {
        window.location.href = `/signupInvitedGuestList?event-slug=${eventSlug}&guestWhoInvited=${inviteID}`;
      }
      else if (guest?._id) {
        addGuestListExistingUserMutation.mutateAsync({
          auth_service_id: stytchUser?.user?.user_id,
          event_slug: eventSlug,
          invite_code: inviteID,

        }).then(resp => {
          toast({
            title: 'Success',
            description: 'You have been added to the guest list.',
            status: 'success',
            duration: 9000,
            isClosable: true,
          });
          window.location.href = `/specificEvent/${eventSlug}`;
        }).catch(err => {
          toast({
            title: 'Error',
            description: err.message,
            status: 'error',
            duration: 9000,
            isClosable: true,
          });
        })
      }

      console.log('guest', guest);
    }
  }, [stytchClient, stytchUser, guest, loading]);

  const sendPasscode = useCallback(() => {
    // Add +1 to the phone number to make it a US number
    const formattedPhoneNumber = `${phoneNumber.replace(
      /[\s()-]/g,
      ''
    )}`.trim();
    stytchClient.otps.sms
      .loginOrCreate(formattedPhoneNumber, {
        expiration_minutes: 10,
      })
      .then(resp => {
        console.log('resp', resp);
        setMethodId(resp.method_id);
        setCodeSent(true);
      })
      .catch(err => {
        toast({
          title: 'Error',
          description: err.message,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });

        console.log('err', err);
      });

    setCodeSent(true);
  }, [stytchClient, phoneNumber]);

  const login = useCallback(() => {
    stytchClient.otps
      .authenticate(code, methodId, {
        session_duration_minutes: 60,
      })
      .then(resp => {
        console.log('resp', resp);
      })
      .catch(err => {
        toast({
          title: 'Error',
          description: 'Invalid OPT code, try sending a new one.',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });

        console.log('err', err);
      });
  }, [stytchClient, code, methodId]);

  const resetLogin = useCallback(() => {
    setCodeSent(false);
    setCode('');
    setPhoneNumber('');
    setMethodId('');
  }, []);

  return loadingInviteIDCheck ? (
    <Box
      h="100vh"
      w="100vw"
      alignContent="center"
      justifyContent="center"
      bgSize="cover"
      bgPosition="center"
      display="flex"
      opacity={'0.9'}
      bgImage={'/images/inviteGifBackground.gif'}
    >
      <VStack>
        <Box marginTop="5vh">
          <RasaLogo />
        </Box>

        <Box
          w="80vw"
          h="80vh"
          bg="white"
          borderRadius="20px"
          boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <VStack>
            <Text
              fontSize="24px"
              fontWeight="bold"
              color="black"
              textAlign="center"
            >
              Loading...
            </Text>
          </VStack>
        </Box>
      </VStack>
    </Box>
  ) : !valid?._id ? (
    <Box
      h="100vh"
      w="100vw"
      alignContent="center"
      justifyContent="center"
      bgSize="cover"
      bgPosition="center"
      display="flex"
      bgImage={
        'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/images/inviteGifBackground.gif)'
      }
      bgRepeat="no-repeat"
    >
      <VStack>
        <Box marginTop="5vh">
          <RasaLogo />
        </Box>

        <Box
          marginTop="5vh"
          h="100vh"
          w="90vw"
          borderRadius="18px"
          alignContent="center"
          justifyContent="center"
          bgSize="cover"
          bgPosition="center"
          display="flex"
          bgImage={
            'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/images/inviteGifBackground.gif)'
          }
          bgRepeat="no-repeat"
        >
          <VStack>
            <Text
              marginTop={'3vh'}
              fontSize="3xl"
              fontWeight={'bold'}
              bgGradient="linear(to-l, #BDD7F180, #FFFFFF)"
              bgClip="text"
            >
              Invalid Invite
            </Text>
          </VStack>
        </Box>
      </VStack>
    </Box>
  ) : (
    <>
      <Box
        h="100vh"
        w="100vw"
        alignContent="center"
        justifyContent="center"
        bgSize="cover"
        bgPosition="center"
        display="flex"
        alignContent="center"
        justifyContent="center"
        bgSize="cover"
        bgPosition="center"
        display="flex"
        bgImage={
          'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/images/inviteGifBackground.gif)'
        }
        bgRepeat="no-repeat"
      >
        <VStack>
          <Box marginTop="5vh">
            <RasaLogo />
          </Box>

          <Box
            marginTop="5vh"
            h="100vh"
            w="90vw"
            bgColor="#0C0A29"
            borderRadius="18px"
            borderWidth={'3px'}
          >
            <VStack>
              <Text
                marginTop={'3vh'}
                fontSize="3xl"
                fontWeight={'bold'}
                bgGradient="linear(to-l, #BDD7F180, #FFFFFF)"
                bgClip="text"
                textAlign={'center'}
              >
                {valid?.name?.first_name} {valid?.name?.last_name} has <br />{' '}
                invited <br /> YOU to RASA
              </Text>

              <Text
                maxW={'60vw'}
                fontSize="md"
                color="#6F87A0EB"
                opacity="0.9"
                textAlign={'center'}
                marginRight={'2vw'}
              >
                Enter your phone number below to join the party.
              </Text>

              <FormControl marginTop={'20px'} id="number" isRequired>
                {!codeSent && (
                  <VStack>
                    <Flex
                      color="#6F87A0EB"
                      opacity="0.92"
                      direction="column"
                      align="flex-start"
                      width="70vw"
                    >
                      <Text fontWeight="bold" mb={2}>
                        PHONE NUMBER
                      </Text>
                      <ChakraPhone
                        defaultCountry="us"
                        value={phoneNumber}
                        onChange={phone => {
                          setPhoneNumber(phone);
                          setIsValid(isPhoneValid(phone));
                        }}
                      />
                    </Flex>
                    <Button
                      marginTop={'10px'}
                      marginBottom={'20px'}
                      onClick={sendPasscode}
                      borderColor={'gray.700'}
                      borderWidth={'1px'}
                      opacity={'0.92'}
                      width={'80vw'}
                      animation={`${gradient} 1s ease infinite`}
                      bgGradient="linear(to-l, #9763B4, #8978FB)"
                      isDisabled={!isValid}
                    >
                      Continue
                    </Button>
                  </VStack>
                )}

                <VStack>
                  {codeSent && (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ duration: 1 }}
                    >
                      <VStack width={'70vw'} textAlign={'center'}>
                        <Text
                          fontSize="xs"
                          color="#6F87A0EB"
                          opacity="0.9"
                          textAlign={'center'}
                        >
                          Sent to {phoneNumber}
                        </Text>
                        <Text
                          fontSize="xs"
                          color="#6F87A0EB"
                          opacity="0.9"
                          textAlign={'center'}
                        >
                          Please enter the unique code sent to your phone.
                        </Text>
                        <HStack spacing={'10px'}>
                          <OtpInput
                            inputStyle={{
                              width: '10vw',
                              height: '10vw',
                              fontSize: '5vw',
                              borderRadius: '10px',
                              border: '1px solid #6F87A0EB',
                              color: 'white',
                              backgroundColor: '#0C0A29',
                              margin: '0px',
                              textAlign: 'center',
                              fontWeight: 'bold',
                            }}
                            value={code}
                            onChange={value => setCode(value)}
                            numInputs={6}
                            separator={<span>&nbsp;&nbsp;</span>}
                          />
                        </HStack>

                        <Button
                          fontSize={'sm'}
                          marginTop={'10px'}
                          onClick={login}
                          borderColor={'gray.700'}
                          borderWidth={'1px'}
                          opacity={'0.92'}
                          width={'80vw'}
                          animation={`${gradient} 1s ease infinite`}
                          bgGradient="linear(to-l, #9763B4, #8978FB)"
                        >
                          Verify Phone Number
                        </Button>
                        <Text
                          fontSize="xs"
                          color="#6F87A0EB"
                          opacity="0.9"
                          textAlign={'center'}
                        >
                          Didn't receive a code?
                        </Text>
                        <Text
                          fontSize="xs"
                          color="#6F87A099"
                          textAlign={'center'}
                          onClick={resetLogin}
                        >
                          Resend Code
                        </Text>
                      </VStack>
                    </motion.div>
                  )}
                </VStack>
              </FormControl>
            </VStack>
          </Box>
        </VStack>
      </Box>
    </>
  );
}
