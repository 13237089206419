import {
  Avatar,
  Box,
  HStack,
  Text,
  VStack,
  Flex,
  Divider,
} from '@chakra-ui/react';

import { useStytchUser } from '@stytch/react';
import { useGuest } from '../api';
import { LoadingScreen } from '../loader';

export function ProfilePage() {
  const stytchUser = useStytchUser();
  const { guest, loading } = useGuest(stytchUser?.user?.user_id);

  console.log(guest);

  return loading ? (
    <LoadingScreen />
  ) : (
    <Box
      justifyContent="center"
      alignItems="center"
      display="flex"
      height="100vh"
      width="100vw"
      bgColor="#000000"
    >
      <VStack>
        <Avatar
          size="xl"
          name={`${guest?.name.first_name} ${guest?.name.last_name}`}
        />

        <HStack>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            p="12px 0"
            gap="2px"
            w="94.67px"
            h="70px"
            bgColor="#210421"
            borderRadius="11px"
            flex="none"
            order={0}
            flexGrow={1}
          >
            <Text
              fontStyle="normal"
              fontWeight={500}
              fontSize="16px"
              lineHeight="20px"
              letterSpacing="0.15px"
              color="#FFFFFF"
              w="18px"
              h="20px"
              flex="none"
              order={0}
              flexGrow={0}
            >
              {Object.keys(guest?.events).length}
            </Text>

            <Text
              fontStyle="normal"
              fontWeight={400}
              fontSize="16px"
              lineHeight="24px"
              textAlign="center"
              color="#A5B0B3"
              w="82px"
              h="24px"
              flex="none"
              order={1}
              flexGrow={0}
            >
              Events
            </Text>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            p="12px 0"
            gap="2px"
            w="94.67px"
            h="70px"
            bgColor="#210421"
            borderRadius="11px"
            flex="none"
            order={0}
            flexGrow={1}
          >
            <Text
              fontStyle="normal"
              fontWeight={500}
              fontSize="16px"
              lineHeight="20px"
              letterSpacing="0.15px"
              color="#FFFFFF"
              h="20px"
              flex="none"
              order={0}
              flexGrow={0}
            >
              {Object.values(guest?.events).reduce(
                (acc, event) => acc + event.invitedPersons.length,
                0
              )}
            </Text>

            <Text
              fontStyle="normal"
              fontWeight={400}
              fontSize="16px"
              lineHeight="24px"
              textAlign="center"
              color="#A5B0B3"
              w="82px"
              h="24px"
              flex="none"
              order={1}
              flexGrow={0}
            >
              Guests
            </Text>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            p="12px 0"
            gap="2px"
            w="94.67px"
            h="70px"
            bgColor="#210421"
            borderRadius="11px"
            flex="none"
            order={0}
            flexGrow={1}
          >
            <Text
              fontStyle="normal"
              fontWeight={500}
              fontSize="16px"
              lineHeight="20px"
              letterSpacing="0.15px"
              color="#FFFFFF"
              w="18px"
              h="20px"
              flex="none"
              order={0}
              flexGrow={0}
            >
              0
            </Text>

            <Text
              fontStyle="normal"
              fontWeight={400}
              fontSize="16px"
              lineHeight="24px"
              textAlign="center"
              color="#A5B0B3"
              w="82px"
              h="24px"
              flex="none"
              order={1}
              flexGrow={0}
            >
              Reward
            </Text>
          </Box>
        </HStack>
        <Box
          w="350px"
          h="353px"
          bg="#210421"
          border="0.5px solid #2E092A"
          borderRadius="16px"
          p="22px 24px 22px 20px"
          d="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="flex-start"
          gap="16px"
          position="relative"
        >
          <VStack justifyContent={'space-between'} h="100%">
            <HStack justifyContent="space-between" w="100%">
              <Text fontWeight={'thin'} textColor={'lightgray'}>
                Name
              </Text>
              <Text>{`${guest?.name.first_name} ${guest?.name.last_name}`}</Text>
            </HStack>
            <Divider />
            <HStack justifyContent="space-between" w="100%">
              <Text fontWeight={'thin'} textColor={'lightgray'}>
                Phone Number
              </Text>
              <Text>{guest?.phone}</Text>
            </HStack>
            <Divider />
            <HStack justifyContent="space-between" w="100%">
              <Text fontWeight={'thin'} textColor={'lightgray'}>
                City
              </Text>
              <Text>{guest?.location}</Text>
            </HStack>
            <Divider />
            <HStack justifyContent="space-between" w="100%">
              <Text fontWeight={'thin'} textColor={'lightgray'}>
                Email
              </Text>
              <Text>{guest?.email}</Text>
            </HStack>
            <Divider />
            <HStack justifyContent="space-between" w="100%">
              <Text fontWeight={'thin'} textColor={'lightgray'}>
                IG Handle
              </Text>
              <Text>{guest?.igHandle}</Text>
            </HStack>
            <Divider />
          </VStack>
        </Box>
      </VStack>
    </Box>
  );
}
