import React from 'react';
import PropTypes from 'prop-types';

export default function TicketIcon({ text }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="45"
      height="45"
      fill="none"
      viewBox="0 0 45 45"
      className="TicketCounter__STicketCounterIcon-sc-b3e984eb-2 diVyKJ"
    >
      <g clipPath="url(#ticket-counter_svg__a)">
        <mask id="ticket-counter_svg__b" fill="#fff">
          <path
            fillRule="evenodd"
            d="M28 0a5 5 0 0 1-10 0H2a2 2 0 0 0-2 2v41a2 2 0 0 0 2 2h16a5 5 0 0 1 10 0h15a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H28Z"
            clipRule="evenodd"
          ></path>
        </mask>
        <path
          fill="#333"
          fillRule="evenodd"
          d="M28 0a5 5 0 0 1-10 0H2a2 2 0 0 0-2 2v41a2 2 0 0 0 2 2h16a5 5 0 0 1 10 0h15a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H28Z"
          clipRule="evenodd"
        ></path>
        {/* Other paths and elements */}
        <path
          fill="#464646"
          d="M28 0v-1h-1v1h1ZM18 0h1v-1h-1v1Zm0 45v1h1v-1h-1Zm10 0h-1v1h1v-1ZM23 6a6 6 0 0 0 6-6h-2a4 4 0 0 1-4 4v2Zm-6-6a6 6 0 0 0 6 6V4a4 4 0 0 1-4-4h-2ZM2 1h16v-2H2v2ZM1 2a1 1 0 0 1 1-1v-2a3 3 0 0 0-3 3h2Zm0 41V2h-2v41h2Zm1 1a1 1 0 0 1-1-1h-2a3 3 0 0 0 3 3v-2Zm16 0H2v2h16v-2Zm1 1a4 4 0 0 1 4-4v-2a6 6 0 0 0-6 6h2Zm4-4a4 4 0 0 1 4 4h2a6 6 0 0 0-6-6v2Zm20 3H28v2h15v-2Zm1-1a1 1 0 0 1-1 1v2a3 3 0 0 0 3-3h-2Zm0-41v41h2V2h-2Zm-1-1a1 1 0 0 1 1 1h2a3 3 0 0 0-3-3v2ZM28 1h15v-2H28v2Z"
          mask="url(#ticket-counter_svg__b)"
        ></path>
      </g>
      <defs>
        <clipPath id="ticket-counter_svg__a">
          <path fill="#fff" d="M0 0h45v45H0z"></path>
        </clipPath>
      </defs>
      {/* Text element */}
      <text
        x="22.5" // Half of the width
        y="22.5" // Half of the height
        fill="#fff" // Text color
        fontSize="20" // Adjust font size as needed
        textAnchor="middle"
        dominantBaseline="central"
      >
        {text}
      </text>
    </svg>
  );
}

TicketIcon.propTypes = {
  text: PropTypes.string.isRequired, // Making text a required prop
};
