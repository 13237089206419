import React from 'react';
import { CountrySelector, usePhoneInput } from 'react-international-phone';
import { Box, Input, Button, Flex, Text, HStack } from '@chakra-ui/react';

export const ChakraPhone = ({ value, onChange }) => {
  const phoneInput = usePhoneInput({
    defaultCountry: 'us',
    inputValue: value,
    onChange: (phone, data) => {
      onChange(phone);
    },
    disableDialCodeAndPrefix: true,
  });

  if (phoneInput.country.iso2 === 'fr') {
    phoneInput.inputValue = phoneInput.inputValue.replace(/^0+/, '');
  }

  return (
    <Flex alignItems="center">
      <CountrySelector
        selectedCountry={phoneInput.country.iso2}
        onSelect={(country) => phoneInput.setCountry(country.iso2)}
        renderButtonWrapper={({ children, rootProps }) => (
          <Button {...rootProps} mr="2" bg="#0A010A" colorScheme="teal" size="md">
            {children}
          </Button>
        )}
      />
      <Flex border="1px" borderColor="gray.700" borderRadius="lg" alignItems="center">
        <HStack>
        <Box bg="#0A010A" px="3" py="2" borderRadius="lg" mr="-1px" display="flex" alignItems="center" borderRight="1px" borderColor="gray.700">
          <Text color="gray.200">{'+' + phoneInput.country.dialCode}</Text>
        </Box>
        <Input
          placeholder="Mobile number"
          type="tel"
          bg="#0A010A"
          color="gray.200"
          borderRadius="lg"
          value={phoneInput.inputValue}
          onChange={phoneInput.handlePhoneValueChange}
          ref={phoneInput.inputRef}
          autoComplete="tel-national"
          border="none"
          _focus={{
            boxShadow: 'none',
          }}
          size="md"
        />
        </HStack>
      </Flex>
    </Flex>
  );
};
