import * as React from 'react';
import PropTypes from 'prop-types';
import {
  faCircle,
  faCircleCheck,
  faArrowRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Divider,
  Input,
  Flex,
  Box,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

import { useStytchSession } from '@stytch/react';


import { useStytch, useStytchUser } from '@stytch/react';
import BuyTicketEventDetails from './PurchaseTIcketEventDetails';
import LoginBlock from './LoginBlock';

import { useGuest } from '../../api';

import PurchaseTicketsBlock from './PurchaseTicketsBlock';
//import BuyTicketCheckout from "./BuyTicketCheckout";
//import BuyTicketPurchased from "./BuyTicketPurchase";

export default function BuyTicketModal({
  eventDetails,
  tickets,
  setTicketsSelected,
  ticketsSelected,
  isOpen,
  onOpen,
  onClose,
  promoter,
}) {
  const [step, setStep] = React.useState(1);

  const stytchClient = useStytch();
  const stytchUser = useStytchUser();
  const stytchSess = useStytchSession();
  const { guest, loading } = useGuest(stytchUser?.user?.user_id);

  React.useEffect(() => {
    console.log(ticketsSelected);
    console.log(step);
  }, [ticketsSelected, step]);

  React.useEffect(() => {
    // if the session is expired, log the user out
    console.log(stytchSess);
    if (stytchSess?.session == null) {
      //window.location.href = '/loginPublicCheckout';
    }

    
    }, [stytchSess, guest]);
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
      <ModalOverlay />
      <ModalContent bgColor="purple.900">
        <ModalHeader>Complete Checkout</ModalHeader>
        <ModalCloseButton />
        <ModalBody bgColor="purple.900">
            {/* Purchase flow header */}
            <Flex
              direction="row"
              gap="4"
              alignItems="center"
              justifyContent="space-between"
            >
              
              <Flex alignItems="center">
              <FontAwesomeIcon
                  icon={step > 1 ? faCircleCheck : faCircle}
                  color={step === 1 ? 'yellow' : 'white'}
                />
                <Text ml="2" color={step === 1 ? 'yellow.300' : 'gray.400'}>
                  Register
                </Text>
                </Flex>
                <FontAwesomeIcon icon={faArrowRight} color="white" />
                <Flex alignItems="center">
              
              
                <FontAwesomeIcon
                  icon={step > 2 ? faCircleCheck : faCircle}
                  color={step === 2 ? 'yellow' : 'white'}
                />
                <Text ml="2" color={step === 2 ? 'yellow.300' : 'gray.400'}>
                  Purchase
                </Text>
              </Flex>
              <FontAwesomeIcon icon={faArrowRight} color="white" />
              <Flex alignItems="center">
                <FontAwesomeIcon
                  icon={step > 3 ? faCircleCheck : faCircle}
                  color={step === 3 ? 'yellow' : 'white'}
                />
                <Text ml="2" color={step === 3 ? 'yellow.300' : 'gray.400'}>
                  🎉
                </Text>
              </Flex>
            </Flex>

            {/* Purchase flow content */}
            {step === 1 && (
              <LoginBlock
                stytchClient={stytchClient}
                stytchUser={stytchUser}
                confirmLogin={() => setStep(2)}
              />
            )}
            {step === 2 && (
              <Flex direction="column" gap="4" alignItems="center">
                <BuyTicketEventDetails eventDetails={eventDetails} />
                <PurchaseTicketsBlock
                  tickets={tickets}
                  ticketsSelected={ticketsSelected}
                  setTicketsSelected={setTicketsSelected}
                  stytchUser={stytchUser}
                  apiGuest={guest}
                  promoter={promoter}
                />
              </Flex>
            )}
            
          
        </ModalBody>
        <ModalFooter>{/* Add any modal footer content here */}</ModalFooter>
      </ModalContent>
    </Modal>
  );
}

BuyTicketModal.propTypes = {
  eventDetails: PropTypes.object.isRequired,
  ticketsSelected: PropTypes.object.isRequired,
  setTicketsSelected: PropTypes.func.isRequired,
  tickets: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onOpenChange: PropTypes.func.isRequired,
};
