import 'react-international-phone/style.css';

import { useCallback, useState, useEffect } from 'react';
import LogRocket from 'logrocket';

import {
  useColorMode,
  Text,
  Box,
  Image,
  VStack,
  FormControl,
  keyframes,
  useToast,
  HStack,
} from '@chakra-ui/react';
import { useStytch, useStytchUser } from '@stytch/react';

import { RasaLogo } from './logo';
import { useGuest, checkForFreeTicket } from '../api';
import { useFetchPromoterByID, useCheckForFreeTicket } from '../api';
import { NEXT_PUBLIC_DEPLOY_URL } from '../constants';
import { useParams } from 'react-router-dom';

import PhoneNumberLogin from './components/PhoneNumberLogin';
import ConfirmCode from './components/ConfirmCode';

export function LoginPage() {
  const gradient = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

  const stytchClient = useStytch();
  const stytchUser = useStytchUser();
  const { guest, loading } = useGuest(stytchUser?.user?.user_id);

  const { slug } = useParams();

  const free_ticket = useCheckForFreeTicket(stytchUser?.user?.user_id, slug);

  const params = new URLSearchParams(window.location.search);
  const promoterID = params.get('special');
  const accessToken = params.get('accessToken');

  const { data: promoter, isLoading: promoterLoading } =
    useFetchPromoterByID(promoterID);

  const redirect2Ticket = params.get('redirect2Ticket');

  const [codeSent, setCodeSent] = useState(false);
  const [code, setCode] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [methodId, setMethodId] = useState('');

  const [otpLoading, setOtpLoading] = useState(false);

  const toast = useToast();

  useColorMode().setColorMode('dark');

  useEffect(() => {
    if (stytchUser?.user?.user_id != undefined && !loading && guest != null) {
      if (loading) return;

      if (guest?.approvalStatus === 'approved') {
        LogRocket.identify(stytchUser?.user?.user_id, {
          name: guest?.firstName + ' ' + guest?.lastName,
          email: guest?.email,
          phone: guest?.phoneNumber,
        });

        console.log('promoterID', promoterID);

        if (guest?.approvalStatus === 'approved') {
          // fetch /api/issueFreeSandsTicket/{issuer}/{auth_service_id} GET request
          //checkForFreeTicket(stytchUser?.user?.user_id, slug).then(res => {
          //  console.log('res', res);
          //});
          // If the guest has a ticket in their account:
          // - redirect to the event page
          var redirectString = "";
          
          redirectString = '/spEvent/groove?special=' + promoterID;
          

          if (redirect2Ticket) {
            window.location.href = redirectString + '&redirectToTicket=true';
          } else {
            window.location.href = redirectString;
          }
        } else {
          window.location.href = '/confirmSignup';
        }
      } else if (guest?.approvalStatus === 'pending') {
        window.location.href = '/confirmSignup';
      } else {
        if (slug) {
          window.location.href = '/signup/' + slug;
        } else {
          window.location.href = '/signup';
        }
      }
    }
  }, [stytchClient, stytchUser, guest, loading]);

  const resetLogin = useCallback(() => {
    setCodeSent(false);
    setCode('');
    setPhoneNumber('');
    setMethodId('');
  }, []);

  return (
    <>
      <Box h="100vh" w="100vw" position="relative">
        <Box
          position="absolute"
          width="100vw"
          height="100vh"
          zIndex="-2"
          overflow="hidden"
          sx={{
            '::after': {
              content: '""',
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              bgGradient: 'linear(to-b, rgba(0, 0, 0, 0.08) 40%, #130213 85%)',
              zIndex: 0,
            },
          }}
        >
          <video
            autoPlay
            loop
            playsInline
            muted
            style={{
              minHeight: '100%',
              minWidth: '100%',
              width: 'auto',
              height: 'auto',
              objectFit: 'cover',
            }}
          >
            <source src="/images/login-background.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Box>
        <VStack>
          <Box
            position="absolute"
            top="3%"
            left="50%"
            transform="translate(-50%, -50%)"
          >
            <RasaLogo />
          </Box>
          <Box
            marginTop="25vh"
            h="80vh"
            w="90vw"
            bg="transparent"
            borderRadius="18px"
            display="flex"
            justifyContent="start"
            alignItems="end"
          >
            <VStack justifyContent="end" alignItems="start">
              {!codeSent && (
                <Text
                  marginTop={'3vh'}
                  fontSize="4xl"
                  textAlign="start"
                  lineHeight="1.2"
                >
                  LOGIN OR SIGNUP
                </Text>
              )}
              {codeSent && (
                <Text
                  marginTop={'3vh'}
                  fontSize="4xl"
                  textAlign="start"
                  lineHeight="1.2"
                  marginBottom="0"
                >
                  ENTER CODE
                </Text>
              )}

              <FormControl id="number" isRequired>
                {!codeSent && (
                  <PhoneNumberLogin
                    phoneNumber={phoneNumber}
                    setPhoneNumber={setPhoneNumber}
                    setCodeSent={setCodeSent}
                    setMethodId={setMethodId}
                    stytchClient={stytchClient}
                    toast={toast}
                    gradient={gradient}
                  />
                )}
                {codeSent && (
                  <ConfirmCode
                    phoneNumber={phoneNumber}
                    setCode={setCode}
                    code={code}
                    setOtpLoading={setOtpLoading}
                    stytchClient={stytchClient}
                    toast={toast}
                    methodId={methodId}
                    otpLoading={otpLoading}
                    loading={loading}
                    resetLogin={resetLogin}
                    gradient={gradient}
                  />
                )}
              </FormControl>
            </VStack>
          </Box>
        </VStack>
      </Box>
    </>
  );
}
