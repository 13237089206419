import React from 'react';
import { VStack, Spinner, useToast } from '@chakra-ui/react';

import { useStytch, useStytchUser,  } from '@stytch/react';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { useGuest } from '../../api';
import PhoneInput from './PhoneInput';
import OtpVerification from './OTPVerifcation';
import SignupForm from './SignupForm';
import LogRocket from 'logrocket';
import { createGuestSpringThrill } from '../../api';

export default function LoginBlock({ setUserLoggedIn, confirmLogin }) {
  const stytchClient = useStytch();
  const user = useStytchUser();

  const params = new URLSearchParams(window.location.search);
  const promoterID = params.get('special');

  const { guest, loading } = useGuest(user?.user?.user_id);

  const toast = useToast();

  const [step, setStep] = React.useState(1);
  const [phone, setPhone] = React.useState('');
  const [otp, setOtp] = React.useState('');

  const [codeSent, setCodeSent] = React.useState(false);
  const [methodId, setMethodId] = React.useState('');
  const [defaultUserLoading, setDefaultUserLoading] = React.useState(false);

  const [otpLoading, setOtpLoading] = React.useState(false);

  const [isValid, setIsValid] = React.useState(false);
  const [countryCode, setCountryCode] = React.useState('US');

  const phoneUtil = PhoneNumberUtil.getInstance();

  const isPhoneValid = (phone, countryCode) => {
    try {
      return phoneUtil.isValidNumber(
        phoneUtil.parseAndKeepRawInput(phone, countryCode)
      );
    } catch (error) {
      return false;
    }
  };

  React.useEffect(() => {
    
    if (user?.user && !loading && guest?._id) {
      LogRocket.identify(user?.user?.user_id, {
        name: guest?.name?.first_name + ' ' + guest?.name?.last_name,
        email: guest?.email,
        phone: guest?.phone,
      });
      confirmLogin();
    }

  }, [user, loading, guest]);

  const handleSendCode = () => {
    const formattedPhoneNumber = `${phone.replace(/[\s()-]/g, '')}`.trim();

    stytchClient.otps.sms
      .loginOrCreate(formattedPhoneNumber, {
        expiration_minutes: 10,
      })
      .then(resp => {
        console.log('resp', resp);
        setCodeSent(true);
        setMethodId(resp.method_id);
      })
      .catch(err => {
        console.log('err', err);
      });
  };

  const handleResendCode = () => {
    const formattedPhoneNumber = `${phone.replace(/[\s()-]/g, '')}`.trim();

    stytchClient.otps.sms
        .loginOrCreate(formattedPhoneNumber, {
            expiration_minutes: 10,
            })
        .then(resp => {
            console.log('resp', resp);
            setCodeSent(true);
            setMethodId(resp.method_id);
            })
        .catch(err => {
            console.log('err', err);
            });
    };

  const handleVerifyCode = () => {
    setOtpLoading(true);
    stytchClient.otps
      .authenticate(otp, methodId, {
        session_duration_minutes: 60,
      })
      .then(resp => {
        console.log('resp', resp);
        setOtpLoading(false);
        setStep(3);
      })
      .catch(err => {
        toast({
          title: 'Error',
          description: 'Invalid OPT code, try sending a new one.',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
        setOtpLoading(false);
        console.log('err', err);
      });
  };

  const handleContinue = () => {
    // Add your continue logic here
    setUserLoggedIn(true);
  };

  if (loading) {
    return (
      <VStack w="full" h="full" justifyContent="center" alignItems="center">
        <Spinner size="xl" />
      </VStack>
    );
  }

  return (
    <VStack
      spacing={4}
      p={4}
      rounded="xl"
      alignItems="center"
      w="full"
      bg={step < 4 ? 'purple.900' : 'black'}
      mt={5}
    >

      
      
      {step === 1 && (
        <PhoneInput
          onSendCode={() => {
            handleSendCode();
            setStep(2);
          }}
          phone={phone}
          setPhone={setPhone}
          isValid={isValid}
          onInputChange={(phone, data) => {
            // if country iso is france, remove leading 0
            if (phone?.country?.iso2 === 'fr') {
              phone.phone = phone.phone.replace('+330', '+33');
              phone.phoneInput = phone.inputValue.replace('+330', '+33');
            }
            setCountryCode(phone?.country?.iso2 || 'us');
            setPhone(phone.phone);
            setIsValid(isPhoneValid(phone.phone, phone?.country?.iso2 || 'us'));
          }}
        />
      )}

      {step === 2 && (
        <OtpVerification
          otp={otp}
          setOtp={setOtp}
          onVerifyCode={handleVerifyCode}
          onResendCode={handleResendCode}
        />
      )}

      {step === 3 && <SignupForm onContinue={confirmLogin} />}

      

      {/* Handle step 4 and other logic as before */}
    </VStack>
  );
}
