import { Spinner, Box } from '@chakra-ui/react';

import { Logo } from './Logo';

export function LoadingScreen() {
  return (
    <Box position="relative" m="auto" h="100vh" w="100vw" alignContent={'center'} justifyContent={'center'} display={'flex'} bgColor={'#000000'}>
      
      <Spinner
        position="absolute"
        
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        w={20}
        h={20}
      />
      <Logo position="absolute" h="30px" mt="24px" />
      
    </Box>
  );
}
