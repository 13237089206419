import {
  Box,
  Button,
  HStack,
  Image,
  Text,
  VStack,
  Flex,
  useToast,
} from '@chakra-ui/react';
import { useGuest } from '../api';
import { useStytchUser } from '@stytch/react';
import { useParams } from 'react-router-dom';
import { LoadingScreen } from '../loader';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { useMutation } from 'react-query';
import { removeGuestFromGuestList } from '../api/user';

export function RewardsPage( {event} ) {
  const { slug } = useParams();
  const stytchUser = useStytchUser();
  const { guest, loading } = useGuest(stytchUser?.user?.user_id);
  const toast = useToast();
  const { mutateAsync } = useMutation(removeGuestFromGuestList);

  return loading ? (
    <LoadingScreen />
  ) : (
    <Box
      mt="20px"
      w="100vw"
      background="linear-gradient(180deg, rgba(8, 4, 25, 0) 0%, rgba(85, 102, 255, 0.12) 48.67%, rgba(8, 4, 25, 0) 100%)"
      alignContent={'center'}
      justifyContent={'center'}
      display={'flex'}
      order={1}
      flexGrow={0}
    >
      <VStack>
      <Text
          fontSize="3xl"
          bgGradient="linear(to-l, #FF0080, #7928CA, #FF0080)"
          bgClip="text"
          fontWeight="extrabold"
        >
          SHARE THE FUN
        </Text>

        <a
          w={'100vw'}
          href={`sms://?&body=Hey%21%20I%20am%20going%20to%20this%20party%20in%20LA%20on%20Sat%203%2F16%20at%20Six%20Flags%2C%20come%20with%20me%3A%20https%3A%2F%2Fwww.rasa.world%2Fthrillcitysixflags`}
        >
          <Button mt="10px" variant="outline" borderRadius={'20px'}>
            INVITE FRIENDS
          </Button>
        </a>
      </VStack>
      
    </Box>
  );
}
