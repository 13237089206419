import React from 'react';
import {
  Box,
  Flex,
  Text,
  Divider,
  VStack,
  HStack,
  Link,
  Checkbox,
} from '@chakra-ui/react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { createPaymentIntent, useGuest } from '../../api';
import TicketIcon from './TicketIcon';
import BuyTicketCardInfo from './BuyTicketCardInfo';
import { NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY } from '../../constants';
const stripePromise = loadStripe(NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY);

export default function PurchaseTicketsBlock({
  tickets,
  eventDetails,
  ticketsSelected,
  stytchUser,
  apiGuest,
  promoter,
}) {
  console.log(ticketsSelected);
  console.log(tickets);
  console.log(apiGuest);
  const [userLoggedIn, setUserLoggedIn] = React.useState(false);
  const [termsAccepted, setTermsAccepted] = React.useState(false);

  // Handle change for terms checkbox
  const handleTermsChange = e => {
    setTermsAccepted(e.target.checked);
  };

  const openTermsAndConditions = () => {
    const termsUrl = `${window.location.origin}/termsAndConditions`;
    window.open(termsUrl, '_blank');
  };

  // Construct an array of selected tickets and tiers
  const selectedTicketsDetails = Object.entries(ticketsSelected).map(
    ([tierId, quantity]) => {
      let detail = {
        ticketName: '',
        tierName: '',
        price: 0,
        quantity,
      };

      tickets.forEach(ticket => {
        ticket.tiers.forEach(tier => {
          if (tier._id === tierId) {
            detail.ticketName = ticket.ticketName;
            detail.tierName = tier.tierName;
            detail.price = tier.tierPrice;
          }
        });
      });

      return detail;
    }
  );

  const [clientSecret, setClientSecret] = React.useState('');
  const [paymentIntentId, setPaymentIntentId] = React.useState('');
  React.useEffect(() => {
    console.log(ticketsSelected);

    console.log(promoter);

    // If the user is logged out, redirect back to loginPublicCheckout
    if (!stytchUser.user) {
      window.location.href = '/loginPublicCheckout';
    }

    createPaymentIntent(
      stytchUser.user.user_id,
      'spring-thrill',
      tickets,
      ticketsSelected,
      promoter?.discountAmount || 0,
      promoter?.promoterLinkID || ''
    )
      .then(data => {
        console.log(data);
        setClientSecret(data.clientSecret);
        setPaymentIntentId(data.paymentIntentId);
      })
      .catch(err => console.log(err));
  }, [stytchUser]);

  const appearance = {
    theme: 'night',
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <VStack spacing={4} align="stretch" w="full">
      <Text fontSize="2xl" fontWeight="bold" color="white">
        Order Summary
      </Text>
      <Divider />
      {selectedTicketsDetails.map((selectedTicket, index) => (
        <Flex key={index} justify="space-between" align="center" mb={4}>
          <Box position="relative">
            <TicketIcon text={selectedTicket.quantity} />
          </Box>
          <VStack align="start" spacing={0} flex={1} ml={4}>
            <Text
              fontSize="xl"
              fontWeight="bold"
              textAlign="left"
              color="white"
            >
              {selectedTicket.ticketName} - {selectedTicket.tierName}
            </Text>
            <Text fontSize="sm" color="gray.400">
              Delivery by email to {apiGuest?.email}
            </Text>
          </VStack>
          <VStack align="end">
            <HStack>
              <Text
                fontSize="xl"
                fontWeight="bold"
                textDecoration={
                  promoter?.discountAmount > 0 ? 'line-through' : 'none'
                }
                color="white"
              >
                {'$' +
                  (selectedTicket.price * selectedTicket.quantity).toFixed(2)}
              </Text>
              {promoter?.discountAmount > 0 && (
                <Text fontSize="xl" fontWeight="bold" color="yellow.300">
                  {'$' +
                    (
                      (selectedTicket.price -
                        selectedTicket.price * promoter?.discountAmount) *
                      selectedTicket.quantity
                    ).toFixed(2)}
                </Text>
              )}
            </HStack>
          </VStack>
        </Flex>
      ))}
      <Divider />
      {/* Subtotal */}
      <Flex justify="space-between" align="center" mb={4}>
        <Text fontSize="xl" fontWeight="bold" color="white">
          Subtotal
        </Text>
        <Text fontSize="xl" fontWeight="bold" color="white">
          {'$' +
            selectedTicketsDetails
              .reduce((acc, cur) => acc + cur.price * cur.quantity, 0)
              .toFixed(2)}
        </Text>
      </Flex>
      {/* Fee */}
      <Flex justify="space-between" align="center" mb={4}>
        <Text fontSize="sm" fontWeight="bold" color="gray.400">
          Fees
        </Text>
        <Text fontSize="sm" fontWeight="bold" color="gray.400">
          {'$' +
            (
              selectedTicketsDetails.reduce(
                (acc, cur) => acc + cur.price * cur.quantity,
                0
              ) * 0.05
            ).toFixed(2)}
        </Text>
      </Flex>
      <Divider />
      {/* Total */}
      <Flex justify="space-between" align="center">
        <VStack align="start">
          <Text fontSize="xl" fontWeight="bold" color="white">
            Total
          </Text>
          <Text fontSize="sm" color="gray.400">
            Tax Included
          </Text>
        </VStack>
        <HStack>
          <Text
            fontSize="xl"
            fontWeight="bold"
            textDecoration={
              promoter?.discountAmount > 0 ? 'line-through' : 'none'
            }
            color="white"
          >
            {'$' +
              (
                selectedTicketsDetails.reduce(
                  (acc, cur) => acc + cur.price * cur.quantity,
                  0
                ) +
                selectedTicketsDetails.reduce(
                  (acc, cur) => acc + cur.price * cur.quantity,
                  0
                ) *
                  0.05
              ).toFixed(2)}
          </Text>
          {promoter?.discountAmount > 0 && (
            <Text fontSize="xl" fontWeight="bold" color="yellow.300">
              {'$' +
                (
                  selectedTicketsDetails.reduce(
                    (acc, cur) =>
                      acc +
                      (cur.price - cur.price * promoter?.discountAmount) *
                        cur.quantity,
                    0
                  ) +
                  selectedTicketsDetails.reduce(
                    (acc, cur) => acc + cur.price * cur.quantity,
                    0
                  ) *
                    0.05
                ).toFixed(2)}
            </Text>
          )}
        </HStack>
      </Flex>
      <Divider />
      {/* Terms and Conditions Section */}
      <Flex justify="start" align="center" mb={4}>
        <HStack>
          <Checkbox isChecked={true} onChange={handleTermsChange}></Checkbox>
          <Text fontSize="sm" color="gray.400">
            I agree to the{' '}
            <Link
              color="blue.500"
              cursor="pointer"
              href="https://www.rasa.world/event-terms-conditions"
              isExternal
            >
              <Text as="span" color="blue.500" cursor="pointer">
                Terms and Conditions
              </Text>
            </Link>
          </Text>
        </HStack>
      </Flex>
      <Divider />
      {/* Check if terms are accepted before rendering BuyTicketCardInfo */}
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <BuyTicketCardInfo
          // props passed to BuyTicketCardInfo
          />
        </Elements>
      )}
      {/* Optionally, you can show a message or disable the BuyTicketCardInfo based on termsAccepted */}
      )}
    </VStack>
  );
}
