import React from 'react';
import { Global, css } from '@emotion/react';
import { HStack, InputRightElement, keyframes } from "@chakra-ui/react";

import { chakra, Text, Button, Box, VStack, Input, NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, PinInputField, Divider, FormControl, FormLabel, InputGroup, InputLeftElement } from '@chakra-ui/react';
import { RasaLogo } from './logo';

import { NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY } from '../constants';
import { loadStripe } from '@stripe/stripe-js';
import { useStytch, useStytchUser } from '@stytch/react';
import SVGComponent from './ticketsButton';
import { createTableRequest } from '../api';
// import chevron left icon from react icons
import { FaCheck, FaChevronLeft } from 'react-icons/fa';
import { useGuest, getCouponCode } from '../api';
import InviteFriendsBlock from './inviteFriendsButton';
const stripePromise = loadStripe(NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY);

const pricingTable = {
    vip: "price_1NWLuuBSQCEjtdpVPtCUc6d4",
    general: "price_1NWLv0BSQCEjtdpVTuaz3Dta",
    afterparty: "price_1NZMRYBSQCEjtdpVHil59JoJ"
}

const handleClick = async (user_id, vipQuantity, gaQuantity, afterPartyQuantity, validCoupon) => {
    // Define your product's price ID
    // Call your backend to create the Checkout Session
    const items = []
    if (vipQuantity > 0) {
        items.push({
            price_id: pricingTable.vip,
            quantity: vipQuantity
        })
    }
    if (gaQuantity > 0) {
        items.push({
            price_id: pricingTable.general,
            quantity: gaQuantity
        })
    }
    if (afterPartyQuantity > 0) {
        items.push({
            price_id: pricingTable.afterparty,
            quantity: afterPartyQuantity
        })
    }


    const discount = validCoupon?.stripeCouponID ? [validCoupon?.stripeCouponID] : []

    const response = await fetch('https://rasa-platform-api.fly.dev/api/create-checkout-session', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            items: items,
            user_id: user_id,
            discount_code: discount

        })
    });

    const session = await response.json();

    // When the customer clicks on the button, redirect them to Checkout.
    const stripe = await stripePromise;
    const { error } = await stripe.redirectToCheckout({
        sessionId: session.id,
    });

    if (error) {
        console.warn('Error:', error);
    }
};

const Riviera = chakra(Box, {
    baseStyle: {
        width: "61px",
        height: "30px",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "20px",
        lineHeight: "30px",
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        background: "linear-gradient(108.44deg, #FFFFFF 0%, rgba(189, 215, 241, 0.5) 100%)",
        order: 0,
        flexGrow: 0,
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
        'background-clip': 'text',
    }
});

export function PurchaseTickets() {

    const gradient = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

    const [vipSelected, setVipSelected] = React.useState(false);
    const [gaSelected, setGaSelected] = React.useState(false);
    const [tableSelected, setTableSelected] = React.useState(false);
    const [afterPartySelected, setAfterPartySelected] = React.useState(false);

    const [vipQuantity, setVipQuantity] = React.useState(0);
    const [gaQuantity, setGaQuantity] = React.useState(0);
    const [afterPartyQuantity, setAfterPartyQuantity] = React.useState(0);

    const stytchUser = useStytchUser();
    const { guest } = useGuest(stytchUser.user.user_id);
    const [coupon, setCoupon] = React.useState(null);
    const [validCoupon, setValidCoupon] = React.useState(null);

    const handleCoupon = async (e) => {
        
        const couponResp = await getCouponCode(coupon);
        console.log(couponResp);
        setValidCoupon(couponResp);
    }

    return (
            <Box
                h="1500px"
                w="100vw"
                alignContent="center"
                justifyContent="center"
                display="flex"
                bgColor={"#0D0A2A"}
            >
                <VStack>
                    <Box marginTop="3vh">

                        <HStack w="90vw" justifyContent="space-between">
                            <VStack align={"left"} textAlign={"left"} justifyContent={"left"} alignContent={"left"} display="flex" marginLeft={"2vw"} size="md" width={"70vw"}>
                                
                                <HStack onClick={() => { window.location.href = "/event"; }} cursor="pointer">
                                    <FaChevronLeft color="#6F87A0" />
                                    <Text fontSize="16px" color="#6F87A0" opacity="0.9">
                                        Back
                                    </Text>

                                </HStack>

                                <Riviera>Riviera</Riviera>
                                <Text fontSize="md" color="#6F87A0EB" opacity="0.9">
                                    July 29 · Los Angeles
                                </Text>
                            </VStack>
                            <Box onClick={() => { window.location.href = "/ticket"; }} cursor="pointer" marginRight={"2vw"}>
                                <SVGComponent />
                            </Box>

                        </HStack>
                    </Box>

                    <Box
                        w="100vw"
                        h="1000px"
                        bgColor="#0D0A2A"
                        borderColor="#140D36"
                        borderWidth={"3px"}
                    >
                        <VStack w="100%">

                            {guest?.numGuests > 0 && (
                                <a href="/ticket">
                                    <InviteFriendsBlock numInvites={guest?.numGuests} />
                                </a>
                            )}



                            <Box

                                w="100vw"
                                bgColor="#0D0A2A"
                                borderColor="#140D36"
                                borderWidth={"3px"}
                            >

                                <VStack align={"left"} p="10px">

                                    <Text fontWeight={"500"} fontSize="20px" textColor={"white"}>
                                        Table
                                    </Text>
                                    <Text fontSize="16px" fontWeight={"400"} color="#9795B5" opacity="0.9">
                                    Separate entry and admission for large groups, dedicated table and serve. Text us at +1 (781) 460 9201
                                    </Text>
                                </VStack>

                                <VStack align="end" marginRight={"1vw"}>
                                    <Button
                                        color='white'
                                        size='sm'
                                        onClick={() => { setTableSelected(true); createTableRequest(stytchUser.user.user_id) }}
                                        marginBottom={'10px'}
                                    >
                                        {tableSelected ? <Text fontSize="md" color="#72EBB1">
                                            REQUEST SENT
                                        </Text> : <Text fontSize="md" bgGradient={"linear(to-l, #BDD7F180, #FFFFFF)"} bgClip="text">
                                            REQUEST
                                        </Text>}
                                    </Button>

                                </VStack>
                            </Box>

                            <Box
                                w="100vw"
                                bgColor="#0D0A2A"
                                borderColor="#140D36"
                                borderWidth={"3px"}
                                onClick={() => { setVipSelected(!vipSelected) }}
                            >

                                <VStack align={"left"} p="10px">

                                    <Text fontWeight={"500"} fontSize="20px" textColor={"white"}>
                                        VIP
                                    </Text>
                                    <Text fontSize="16px" fontWeight={"400"} color="#9795B5" opacity="0.9">
                                    VIP ticket gets you access to the  PRIVATE AFTERPARTY starting at 10PM, as well as access to a separate entry and admission, private area of the venue, dedicated cocktail server, and intimate lounging areas
                                    </Text>
                                </VStack>

                                <VStack align="end" p="10px">
                                    <Text fontSize="md" bgGradient={"linear(to-l, #BDD7F180, #FFFFFF)"} bgClip="text">
                                        ${125 * (vipQuantity > 0 ? vipQuantity : 1)} USD
                                    </Text>
                                    <Box >
                                        <NumberInput size={"lg"} min={0} w="20vw" value={vipQuantity} onChange={(value) => { setVipQuantity(value) }} onClick={(e) => { e.stopPropagation(); }} max={4 - gaQuantity}>
                                            <NumberInputField />
                                            <NumberInputStepper>
                                                <NumberIncrementStepper />
                                                <NumberDecrementStepper />
                                            </NumberInputStepper>
                                        </NumberInput>
                                    </Box>
                                </VStack>
                            </Box>
                            <Box
                                w="100vw"
                                bgColor="#0D0A2A"
                                borderColor="#140D36"
                                borderWidth={"3px"}
                                onClick={() => { setVipSelected(!vipSelected) }}
                            >

                                <VStack align={"left"} p="10px">

                                    <Text fontWeight={"500"} fontSize="20px" textColor={"white"}>
                                        Afterparty
                                    </Text>
                                    <Text fontSize="16px" fontWeight={"400"} color="#9795B5" opacity="0.9">
                                    Access to the PRIVATE AFTERPARTY starting at 10PM
                                    </Text>
                                </VStack>

                                <VStack align="end" p="10px">
                                    <Text fontSize="md" bgGradient={"linear(to-l, #BDD7F180, #FFFFFF)"} bgClip="text">
                                        ${50 * (afterPartyQuantity > 0 ? afterPartyQuantity : 1)} USD
                                    </Text>
                                    <Box >
                                        <NumberInput size={"lg"} min={0} w="20vw" value={afterPartyQuantity} onChange={(value) => { setAfterPartyQuantity(value) }} onClick={(e) => { e.stopPropagation(); }} max={4 - gaQuantity}>
                                            <NumberInputField />
                                            <NumberInputStepper>
                                                <NumberIncrementStepper />
                                                <NumberDecrementStepper />
                                            </NumberInputStepper>
                                        </NumberInput>
                                    </Box>
                                </VStack>
                            </Box>
                            <Box
                                w="100vw"
                                bgColor="#0D0A2A"
                                borderColor="#140D36"
                                borderRadius="18px"
                                borderWidth={"3px"}
                                onClick={() => { setGaSelected(!gaSelected) }}
                            >

                                <VStack align={"left"} p="10px">

                                    <Text fontWeight={"500"} fontSize="20px" textColor={"white"}>
                                    Main Party GA
                                    </Text>
                                    <Text fontSize="16px" fontWeight={"400"} color="#9795B5" opacity="0.9">
                                    For those who love to mainly let go on the dancefloor. Groove with us during the day-party. For after-party access please select VIP.
                                    </Text>
                                </VStack>

                                <VStack align="end" p="10px">
                                    <Text fontSize="md" bgGradient={"linear(to-l, #BDD7F180, #FFFFFF)"} bgClip="text">
                                        ${65 * (gaQuantity > 0 ? gaQuantity : 1)} USD
                                    </Text>
                                    <Box >
                                        <NumberInput size={"lg"} min={0} w="20vw" value={gaQuantity} onChange={(value) => { setGaQuantity(value) }} onClick={(e) => { e.stopPropagation(); }} max={4 - vipQuantity}>
                                            <NumberInputField />
                                            <NumberInputStepper>
                                                <NumberIncrementStepper />
                                                <NumberDecrementStepper />
                                            </NumberInputStepper>
                                        </NumberInput>
                                    </Box>
                                </VStack>
                            </Box>

                            <VStack w="100%" align={"left"} textAlign={"left"} justifyContent={"left"} alignContent={"left"} display="flex" marginLeft={"1vw"} size="md" width={"80vw"}>
                                <Text fontSize="xs" color="#6F87A0EB" opacity="0.9">
                                    UNIQUE CODE
                                </Text>

                                <InputGroup size="md">
                                    <Input placeholder='Enter code' value={coupon} onChange={(e) => { setCoupon(e.target.value) }} />
                                    <InputRightElement width="4.5rem">
                                        <Button h="1.75rem" size="sm" variant="outline" textColor={"#5566FF"} onClick={() => { handleCoupon(coupon) }} isDisabled={validCoupon?._id}>
                                            {validCoupon?._id ? <Text fontSize="xs" color="#72EBB1">
                                                <FaCheck />
                                                </Text> : <Text fontSize="xs" color="#5566FF">
                                                    APPLY
                                                    </Text>}
                                        </Button>
                                    </InputRightElement>
                                </InputGroup>

                            </VStack>
                            <Box
                                width="100%"
                                display="flex"
                                justifyContent="center"
                                padding="1rem"
                                bgColor="#0D0A2A"
                                opacity={"0.90"}
                            >

                                <VStack w="100%" marginTop="20px" align={"left"} textAlign={"left"} justifyContent={"left"} alignContent={"left"} display="flex" marginLeft={"1vw"} size="md" width={"80vw"}>
                                    <Text fontSize="10" color="white" >
                                        TOTAL (INC TAXES AND FEES)
                                    </Text>
                                    { validCoupon?._id ?
                                    <HStack>
                                        
                                    <Text fontWeight={"500"} fontSize="md" color="#white">
                                        $ &nbsp; {(((65 * (gaQuantity) + 125 * (vipQuantity) + 50 * (afterPartyQuantity)) * (1-validCoupon?.discount))).toFixed(2)} &nbsp; USD
                                    </Text>
                                    <Text fontWeight={"500"} fontSize="sm" color="#white" opacity={"0.5"} textDecoration={"line-through"}>
                                        $ &nbsp; {65 * (gaQuantity) + 125 * (vipQuantity) + 50 * (afterPartyQuantity) } &nbsp; USD
                                    </Text>
                                    </HStack>
                                        
                                    :
                                    <Text fontWeight={"extrabold"} fontSize="md" color="#white">
                                        $ &nbsp; {65 * (gaQuantity) + 125 * (vipQuantity)+ 50 * (afterPartyQuantity)} &nbsp; USD
                                    </Text>

                                    }

                                    <Button marginBottom={'20px'} marginTop={'10px'} borderColor={"gray.700"} borderWidth={"1px"} borderRadius={"20px"} opacity={"0.92"} width={"80vw"} animation={`${gradient} 1s ease infinite`} bgGradient="linear(to-r, #9763B4, #8978FB)" isDisabled={vipQuantity + gaQuantity + afterPartyQuantity > 0 ? false : true} onClick={() => { handleClick(stytchUser.user.user_id, vipQuantity, gaQuantity, afterPartyQuantity, validCoupon) }}>
                                        Checkout
                                    </Button>
                                </VStack>

                            </Box>

                        </VStack>
                    </Box>
                </VStack>
            </Box>
    );
}
