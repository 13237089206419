export const RightArrow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="15"
    viewBox="0 0 17 15"
    fill="none"
  >
    <path
      d="M0 7.72931C0 7.34961 0.282154 7.03582 0.648229 6.98615L0.75 6.97931L13.934 6.97991L9.17101 2.23638C8.87749 1.94411 8.87647 1.46924 9.16873 1.17572C9.43442 0.90888 9.85103 0.883777 10.1451 1.101L10.2294 1.17344L16.2794 7.19744C16.3181 7.23596 16.3517 7.27765 16.3802 7.32168C16.3883 7.33494 16.3965 7.3485 16.4042 7.36233C16.4114 7.37418 16.4178 7.38649 16.4239 7.39894C16.4324 7.41705 16.4407 7.43576 16.4482 7.45485C16.4543 7.46957 16.4594 7.48389 16.464 7.49831C16.4696 7.51621 16.4749 7.53528 16.4796 7.55464C16.483 7.56815 16.4857 7.58116 16.4881 7.59422C16.4914 7.61365 16.4943 7.63376 16.4963 7.65411C16.498 7.66962 16.4991 7.685 16.4997 7.70039C16.4998 7.70972 16.5 7.71949 16.5 7.72931L16.4996 7.75836C16.4991 7.77308 16.498 7.78779 16.4966 7.80246L16.5 7.72931C16.5 7.77663 16.4956 7.82294 16.4872 7.86784C16.4853 7.87856 16.483 7.88957 16.4804 7.90053C16.4751 7.92311 16.4689 7.94485 16.4618 7.96616C16.4584 7.97675 16.4543 7.98807 16.45 7.9993C16.4412 8.02187 16.4317 8.04338 16.4212 8.06433C16.4163 8.07419 16.4109 8.0845 16.4052 8.09471C16.3958 8.11137 16.3861 8.1272 16.3759 8.14263C16.3687 8.15355 16.3607 8.16492 16.3524 8.17611L16.3459 8.18475C16.3257 8.21115 16.3038 8.23617 16.2803 8.25964L16.2794 8.26033L10.2294 14.2853C9.93593 14.5776 9.46106 14.5766 9.16877 14.2831C8.90306 14.0163 8.87971 13.5996 9.09818 13.3064L9.17097 13.2225L13.932 8.47991L0.75 8.47931C0.335786 8.47931 0 8.14352 0 7.72931Z"
      fill="#919CFF"
    />
  </svg>
);

export const QRBox = () => (
  <svg
    width="41"
    height="42"
    viewBox="0 0 41 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23.0612 21C23.5329 21 23.9154 21.3824 23.9154 21.8542V23.5625H25.6237C26.0954 23.5625 26.4779 23.9449 26.4779 24.4167C26.4779 24.8884 26.0954 25.2708 25.6237 25.2708H23.0612C22.5895 25.2708 22.207 24.8884 22.207 24.4167V21.8542C22.207 21.3824 22.5895 21 23.0612 21Z"
      fill="#919CFF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.5625 4.77214C2.5625 4.30039 2.94492 3.91797 3.41667 3.91797H13.6667C14.1384 3.91797 14.5208 4.30039 14.5208 4.77214V15.0221C14.5208 15.4939 14.1384 15.8763 13.6667 15.8763H3.41667C2.94492 15.8763 2.5625 15.4939 2.5625 15.0221V4.77214ZM4.27083 5.6263V14.168H12.8125V5.6263H4.27083Z"
      fill="#919CFF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M24.7695 4.77214C24.7695 4.30039 25.152 3.91797 25.6237 3.91797H35.8737C36.3454 3.91797 36.7279 4.30039 36.7279 4.77214V15.0221C36.7279 15.4939 36.3454 15.8763 35.8737 15.8763H25.6237C25.152 15.8763 24.7695 15.4939 24.7695 15.0221V4.77214ZM26.4779 5.6263V14.168H35.0195V5.6263H26.4779Z"
      fill="#919CFF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M24.7695 37.2292C24.7695 36.7574 25.152 36.375 25.6237 36.375H32.457C32.9288 36.375 33.3112 36.7574 33.3112 37.2292C33.3112 37.7009 32.9288 38.0833 32.457 38.0833H25.6237C25.152 38.0833 24.7695 37.7009 24.7695 37.2292Z"
      fill="#919CFF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.082 28.6862C17.082 28.2145 17.4645 27.832 17.9362 27.832H21.3529C21.8246 27.832 22.207 28.2145 22.207 28.6862C22.207 29.1579 21.8246 29.5404 21.3529 29.5404H18.7904V38.082C18.7904 38.5538 18.4079 38.9362 17.9362 38.9362C17.4645 38.9362 17.082 38.5538 17.082 38.082V28.6862Z"
      fill="#919CFF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.5625 19.2917C2.5625 18.8199 2.94492 18.4375 3.41667 18.4375H10.25C10.7217 18.4375 11.1042 18.8199 11.1042 19.2917C11.1042 19.7634 10.7217 20.1458 10.25 20.1458H4.27083V23.5625C4.27083 24.0342 3.88841 24.4167 3.41667 24.4167C2.94492 24.4167 2.5625 24.0342 2.5625 23.5625V19.2917Z"
      fill="#919CFF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.9362 3.0625C18.4079 3.0625 18.7904 3.44492 18.7904 3.91667V19.2917C18.7904 19.7634 18.4079 20.1458 17.9362 20.1458H12.8112C12.3395 20.1458 11.957 19.7634 11.957 19.2917C11.957 18.8199 12.3395 18.4375 12.8112 18.4375H17.082V3.91667C17.082 3.44492 17.4645 3.0625 17.9362 3.0625Z"
      fill="#919CFF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21.3542 7.33203C21.8259 7.33203 22.2083 7.71445 22.2083 8.1862V15.8737C22.2083 16.3454 21.8259 16.7279 21.3542 16.7279C20.8824 16.7279 20.5 16.3454 20.5 15.8737V8.1862C20.5 7.71445 20.8824 7.33203 21.3542 7.33203Z"
      fill="#919CFF"
    />
    <path d="M22.2083 3.91406H20.5V5.6224H22.2083V3.91406Z" fill="#919CFF" />
    <path
      d="M8.54427 21.8516H6.83594V23.5599H8.54427V21.8516Z"
      fill="#919CFF"
    />
    <path
      d="M25.6224 27.8359H23.9141V29.5443H25.6224V27.8359Z"
      fill="#919CFF"
    />
    <path d="M36.7318 36.375H35.0234V38.0833H36.7318V36.375Z" fill="#919CFF" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M31.6055 27.8346C31.6055 27.3629 31.9879 26.9805 32.4596 26.9805H35.8763C36.348 26.9805 36.7305 27.3629 36.7305 27.8346V32.9596C36.7305 33.4314 36.348 33.8138 35.8763 33.8138H32.4596C31.9879 33.8138 31.6055 33.4314 31.6055 32.9596C31.6055 32.4879 31.9879 32.1055 32.4596 32.1055H35.0221V28.6888H32.4596C31.9879 28.6888 31.6055 28.3064 31.6055 27.8346Z"
      fill="#919CFF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.98047 8.1862C5.98047 7.71445 6.36289 7.33203 6.83464 7.33203H10.2513C10.723 7.33203 11.1055 7.71445 11.1055 8.1862V11.6029C11.1055 12.0746 10.723 12.457 10.2513 12.457H6.83464C6.36289 12.457 5.98047 12.0746 5.98047 11.6029V8.1862ZM7.6888 9.04036V10.7487H9.39714V9.04036H7.6888Z"
      fill="#919CFF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.5625 26.9792C2.5625 26.5074 2.94492 26.125 3.41667 26.125H13.6667C14.1384 26.125 14.5208 26.5074 14.5208 26.9792V37.2292C14.5208 37.7009 14.1384 38.0833 13.6667 38.0833H3.41667C2.94492 38.0833 2.5625 37.7009 2.5625 37.2292V26.9792ZM4.27083 27.8333V36.375H12.8125V27.8333H4.27083Z"
      fill="#919CFF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.98047 30.3971C5.98047 29.9254 6.36289 29.543 6.83464 29.543H10.2513C10.723 29.543 11.1055 29.9254 11.1055 30.3971V33.8138C11.1055 34.2855 10.723 34.668 10.2513 34.668H6.83464C6.36289 34.668 5.98047 34.2855 5.98047 33.8138V30.3971ZM7.6888 31.2513V32.9596H9.39714V31.2513H7.6888Z"
      fill="#919CFF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M28.1875 8.1862C28.1875 7.71445 28.5699 7.33203 29.0417 7.33203H32.4583C32.9301 7.33203 33.3125 7.71445 33.3125 8.1862V11.6029C33.3125 12.0746 32.9301 12.457 32.4583 12.457H29.0417C28.5699 12.457 28.1875 12.0746 28.1875 11.6029V8.1862ZM29.8958 9.04036V10.7487H31.6042V9.04036H29.8958Z"
      fill="#919CFF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M28.1888 23.5612C28.1888 23.0895 28.5712 22.707 29.043 22.707H36.7305C37.2022 22.707 37.5846 23.0895 37.5846 23.5612C37.5846 24.0329 37.2022 24.4154 36.7305 24.4154H29.8971V32.957C29.8971 33.4288 29.5147 33.8112 29.043 33.8112H23.0638V38.082C23.0638 38.5538 22.6814 38.9362 22.2096 38.9362C21.7379 38.9362 21.3555 38.5538 21.3555 38.082V32.957C21.3555 32.4853 21.7379 32.1029 22.2096 32.1029H28.1888V23.5612Z"
      fill="#919CFF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M30.75 19.2917C30.75 18.8199 31.1324 18.4375 31.6042 18.4375H36.7292C37.2009 18.4375 37.5833 18.8199 37.5833 19.2917C37.5833 19.7634 37.2009 20.1458 36.7292 20.1458H31.6042C31.1324 20.1458 30.75 19.7634 30.75 19.2917Z"
      fill="#919CFF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.6484 19.2917C19.6484 18.8199 20.0309 18.4375 20.5026 18.4375H29.0443C29.516 18.4375 29.8984 18.8199 29.8984 19.2917C29.8984 19.7634 29.516 20.1458 29.0443 20.1458H20.5026C20.0309 20.1458 19.6484 19.7634 19.6484 19.2917Z"
      fill="#919CFF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.25 22.7096C10.25 22.2379 10.6324 21.8555 11.1042 21.8555H19.6458C20.1176 21.8555 20.5 22.2379 20.5 22.7096V25.2721C20.5 25.7439 20.1176 26.1263 19.6458 26.1263C19.1741 26.1263 18.7917 25.7439 18.7917 25.2721V23.5638H11.1042C10.6324 23.5638 10.25 23.1814 10.25 22.7096Z"
      fill="#919CFF"
    />
  </svg>
);

export const DownArrow = () => (
  <svg viewBox="341.442 192.74 22.511 19.72" width="22.511" height="19.72">
    <path
      d="M 359.821 203.471 L 348.074 203.471 L 353.947 197.687 L 352.013 195.781 L 342.837 204.818 L 352.013 213.855 L 353.947 211.95 L 348.074 206.166 L 362.557 206.166 L 362.557 191.344 L 359.821 191.344 L 359.821 203.471 Z"
      fill="#fff"
      transform="matrix(0, -1, 1, 0, 150.097522, 555.296521)"
    ></path>
  </svg>
);
