import { useCallback, useState } from 'react';

import { Button, VStack, Flex } from '@chakra-ui/react';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { ChakraPhone } from '../internationPhoneNumber';

export default function PhoneNumberLogin({
  phoneNumber,
  setPhoneNumber,
  stytchClient,
  toast,
  setCodeSent,
  setMethodId,
  gradient,
}) {
  const [isValid, setIsValid] = useState(false);
  const [countryCode, setCountryCode] = useState('US');

  const phoneUtil = PhoneNumberUtil.getInstance();

  const isPhoneValid = (phone, countryCode) => {
    try {
      return phoneUtil.isValidNumber(
        phoneUtil.parseAndKeepRawInput(phone, countryCode)
      );
    } catch (error) {
      return false;
    }
  };

  const sendPasscode = useCallback(() => {
    // Add +1 to the phone number to make it a US number
    const formattedPhoneNumber = `${phoneNumber.replace(
      /[\s()-]/g,
      ''
    )}`.trim();

    if (countryCode !== 'us') {
      stytchClient.otps.whatsapp
        .loginOrCreate(formattedPhoneNumber, {
          expiration_minutes: 10,
        })
        .then(resp => {
          console.log('resp', resp);
          toast({
            title: 'Success',
            description: 'We have sent you a WhatsApp message with your code.',
            status: 'success',
            duration: 9000,
            isClosable: true,
          });
          setMethodId(resp.method_id);
          setCodeSent(true);
        })
        .catch(err => {
          toast({
            title: 'Error',
            description: err.message,
            status: 'error',
            duration: 9000,
            isClosable: true,
          });

          console.log('err', err);
        });

      setCodeSent(true);
    } else {
      stytchClient.otps.sms
        .loginOrCreate(formattedPhoneNumber, {
          expiration_minutes: 10,
        })
        .then(resp => {
          console.log('resp', resp);
          setMethodId(resp.method_id);
          setCodeSent(true);
        })
        .catch(err => {
          toast({
            title: 'Error',
            description: err.message,
            status: 'error',
            duration: 9000,
            isClosable: true,
          });

          console.log('err', err);
        });
    }
    setCodeSent(true);
  }, [stytchClient, phoneNumber]);

  return (
    <VStack gap="24px" justifyContent="end" alignItems="start">
      <Flex
        color="white"
        opacity="0.92"
        direction="column"
        align="flex-start"
        width="90vw"
        zIndex={4}
      >
        <ChakraPhone
          value={phoneNumber}
          onChange={(phone, data) => {
            // if country iso is france, remove leading 0
            if (phone?.country?.iso2 === 'fr') {
              phone.phone = phone.phone.replace('+330', '+33');
              phone.phoneInput = phone.inputValue.replace('+330', '+33');
            }
            setCountryCode(phone?.country?.iso2 || 'us');
            setPhoneNumber(phone.phone);
            setIsValid(isPhoneValid(phone.phone, phone?.country?.iso2 || 'us'));
          }}
        />
      </Flex>
      <Button
        onClick={sendPasscode}
        isDisabled={!isValid}
        marginTop={'10px'}
        marginBottom={'20px'}
        border={'1px solid gray.700'}
        borderRadius={'40px'}
        width={'85vw'}
        height={'50px'}
        alignSelf="center"
        color={isValid ? '#0C0A18' : '#3F3C55'}
        background={isValid ? '#DFDFF2' : 'rgba(147.25, 142.86, 197.77, 0.10)'}
        boxShadow={isValid ? '4px 3px 20px rgba(85, 102, 255, 0.30)' : 'none'}
        fontSize="1.2rem"
      >
        NEXT
      </Button>
    </VStack>
  );
}
