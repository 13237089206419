import React from 'react'
import { Image } from '@chakra-ui/react'
import { useColorModeValue } from '@chakra-ui/react'

export function RasaLogo(props) {
    const color = useColorModeValue('#000', '#fff')
    return (
      <Image src="/images/rasa.png" alt="Rasa Logo" {...props} />
    )
  }
  