import { NEXT_PUBLIC_DEPLOY_URL } from '../constants';

export const removeGuestFromGuestList = async ({
  event_slug,
  auth_service_id,
  guest_id,
}) => {
  const res = await fetch(
    `${NEXT_PUBLIC_DEPLOY_URL}/api/events/deleteFromGuestList/${event_slug}/${auth_service_id}/${guest_id}`,
    {
      method: 'POST',
    }
  );
  return res;
};
