import React from 'react';

import { FaArrowLeft } from 'react-icons/fa';
import {
  Box,
  Button,
  HStack,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { useStytchSession } from '@stytch/react';
import TicketTierContainer from './ticketTierContainer';
import BuyTicketModal from './Purchase';
import RequestTableModal from './RequestTableModal';
import { MdKeyboardArrowDown } from 'react-icons/md';

export default function Checkout({
  event,
  promoter,
  setPageState,
  ticketsSelected,
  setTicketsSelected,
  guest,
}) {
  const [total, setTotal] = React.useState(0);

  console.log(promoter);

  const [isBuyOpen, setIsBuyOpen] = React.useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isTableOpen, onTableOpen, onTableClose } = useDisclosure();

  console.log(event);
  console.log(promoter);

  const stytchSess = useStytchSession();

  React.useEffect(() => {
    // if the session is expired, log the user out
    console.log(stytchSess);
    if (stytchSess?.session == null) {
      //window.location.href = '/loginPublicCheckout';
    }
  }, [stytchSess, guest]);

  React.useEffect(() => {
    console.log(ticketsSelected);
    let newTotal = 0;
    event.public_event_tickets.forEach(ticket => {
      ticket.tiers.forEach(tier => {
        if (ticketsSelected[tier._id]) {
          newTotal += ticketsSelected[tier._id] * tier.tierPrice;
        }
      });
    });
    setTotal(newTotal);
  }, [ticketsSelected]);

  return (
    <>
      <Box
        className="font-poppins"
        bg="dark.500"
        p={4}
        rounded="md"
        shadow="md"
        color="gray.400"
        alignItems="center"
      >
        <Button
          justify={'start'}
          align={'start'}
          flexDirection={'row'}
          display={'flex'}
          padding={'1rem'}
          gap={2}
          onClick={() => setPageState('detail')}
        >
          <FaArrowLeft />
          <Text
            justifyContent={'start'}
            align={'start'}
            fontSize={'xl'}
            color={'white'}
          >
            Back to event page
          </Text>
        </Button>

        <Text fontSize={'3xl'} fontWeight="light" color="white" mb={2}>
          {event.eventDetails.eventName}
        </Text>
        <Text fontSize={'xl'} fontWeight="light" color="gray.500" mb={2}>
          {event.eventDetails.eventStartDate} @ {event.eventDetails.eventLocation}
        </Text>
        <VStack spacing={5} w="100%" mb={10}>
          {event.public_event_tickets.map(ticket => (
            <TicketTierContainer
              ticket={ticket}
              ticketsSelected={ticketsSelected}
              setTicketsSelected={setTicketsSelected}
              promoter={promoter}
            />
          ))}
        </VStack>

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="space-between"
          padding="1rem"
          bg="gray.900"
        >
          <VStack gap={0} alignItems="flex-start">
            <HStack>
              <Text
                fontSize="2xl"
                textDecoration={
                  promoter?.discountAmount > 0 ? 'line-through' : 'none'
                }
              >
                ${total.toFixed(2)}
              </Text>
              {promoter?.discountAmount > 0 && (
                <Text fontSize="2xl" color="red.500">
                  $
                  {total.toFixed(2) -
                    (total * promoter?.discountAmount).toFixed(2)}
                </Text>
              )}
            </HStack>
            {/* Placeholder for cheapestPrice */}
          </VStack>

          <VStack>
            <Button
              fontWeight="bold"
              bg="purple.700"
              textColor={'white'}
              borderRadius="full"
              px="8"
              py="4"
              cursor="pointer"
              zIndex="10"
              width="100%"
              isDisabled={total === 0}
              onClick={() => onOpen()}
            >
              CHECKOUT
            </Button>
          </VStack>

          <BuyTicketModal
            eventDetails={{
              title: event.eventDetails.eventName,
              flier: event.eventImageURL,
              date: event.eventDetails.eventStartDate,
              time: event.eventDetails.eventStartTime,
              location: event.eventDetails.eventLocation,
              address: event.eventDetails.eventAddress,
            }}
            tickets={event.public_event_tickets}
            isOpen={isOpen}
            onClose={onClose}
            ticketsSelected={ticketsSelected}
            setTicketsSelected={setTicketsSelected}
            promoter={promoter}
          />
        </Box>
      </Box>
    </>
  );
}
