import React from 'react';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Box,
  useDisclosure,
  Flex,
Input,
Text,
  Button,
} from '@chakra-ui/react';
import { ChevronUpIcon, ChevronDownIcon } from '@chakra-ui/icons';

const Dropdown = ({ticket}) => {
    const [showInput, setShowInput] = React.useState(false); // State to toggle input visibility

    return (
      <Flex direction="column" alignItems="start">
        <Button
          onClick={() => setShowInput(!showInput)} // Toggle input visibility
          rightIcon={showInput ? <ChevronUpIcon /> : <ChevronDownIcon />}
          textColor="#66627A"
        >
          {showInput ? 'Details' : 'Details'}
        </Button>
        {showInput && ( // Conditionally render Input based on `showInput` state
          <Box mt={2} bg="gray.200" p={4} rounded="md" shadow="md" w="100%">
            <Text fontSize="lg" mb={2} textColor={'#66627A'}>
                {ticket.ticketDescription}
            </Text>
            
          </Box>
        )}
      </Flex>
    );
  };
export default Dropdown;
