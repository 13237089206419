import { Box, Image, Text } from "@chakra-ui/react";

export default function BuyTicketEventDetails({ eventDetails }) {
  return (
    <Box display="flex" flexDirection="row">
      <Image
        boxSize="120px"
        src={eventDetails.flier}
        alt="Event flier"
        objectFit="cover"
        borderRadius="xl"
      />
      <Box display="flex" flexDirection="column" justifyContent="start" alignItems="start" ml={4}>
        <Text fontSize="3xl">{eventDetails.title}</Text>
        <Text fontSize="sm" color="gray.500">{eventDetails.date}</Text>
        <Text fontSize="sm" color="gray.500">{eventDetails.location + " - " + eventDetails.address}</Text>
      </Box>
    </Box>
  );
}
