import React from 'react';
import { VStack, Text, Button } from '@chakra-ui/react';
import { ChakraPhone } from './PhoneInputForm'; // Assuming this is already adapted for Chakra UI

const PhoneInput = ({ onSendCode, phone, setPhone, isValid, onInputChange }) => {
  return (
    <VStack spacing={4} w="full" alignItems="center">
      <Text fontSize="3xl" color="white">
        Secure tickets
      </Text>
      <Text color="gray.400" fontSize="sm" textAlign="center">
        Your tickets are tied to your account, which you can access at https://www.rasa.fyi
      </Text>
      <ChakraPhone
        value={phone}
        onChange={onInputChange}
      />
      <Button
        colorScheme="purple"
        variant="solid"
        w="full"
        onClick={onSendCode}
        isDisabled={!isValid}
      >
        CONTINUE
      </Button>
    </VStack>
  );
};

export default PhoneInput;
