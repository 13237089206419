import { useState, useEffect } from 'react';

import { Box, useToast } from '@chakra-ui/react';

import { useStytch, useStytchUser } from '@stytch/react';

import { createGuest, useGuest } from '../api';

import SignUpPageOne from './components/SignUpPageOne';
import SignUpPageTwo from './components/SignUpPageTwo';
import SignUpPageThree from './components/SignUpPageThree';
import SignUpPageFour from './components/SignUpPageFour';
import SignUpConfirmation from './components/SignUpConfirmation';

export function SignUpPage() {
  const stytchClient = useStytch();
  const stytchUser = useStytchUser();
  const { guest, loading } = useGuest(stytchUser?.user?.user_id);
  const toast = useToast();

  const [formFilled, setFormFilled] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [formState, setFormState] = useState({
    firstName: '',
    lastName: '',
    email: '',
    dateOfBirth: '',
    gender: 'DO_NOT_SPECIFY',
    pronouns: '',
    igHandle: '',
    phone: stytchUser?.user?.phone_numbers[0].phone_number,
  });

  const incrementStep = () => {
    setCurrentStep(currentStep + 1);
  };


  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;

    setFormState(prevState => ({
      ...prevState,
      [name]: value,
    }));

    if (
      formState.firstName &&
      formState.lastName &&
      formState.email &&
      formState.dateOfBirth
    ) {
      setFormFilled(true);
    }
  };

  const handleSubmit = e => {
    e.preventDefault();

    submitForm(formState);
  };

  const submitForm = formState => {
    console.log(formState);
    // Post to the API
    createGuest(stytchUser?.user?.user_id, formState)
      .then(resp => {
        console.log(resp);
        if (resp?.approvalStatus == 'pending') {
          toast({
            title: 'Information Received.',
            description:
              'We are reviewing your application, if approved you will receive a text.',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
          incrementStep();
        } else if (resp?.approvalStatus == 'approved') {
          window.location.href = '/introToEvent/spring-thrill';
        } else if (resp.status == 205) {
          toast({
            title: 'Stay Tuned.',
            description:
              'We have already received your application, if approved you will recieve a text.',
            status: 'success',
            duration: 9000,
            isClosable: true,
          });
          incrementStep();
        }
      })
      .catch(err => {
        console.log(err);
        toast({
          title: 'An error occurred.',
          description: 'We were unable to create your account.',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      });
  };

  return (
    <>
      <Box
        h="100vh"
        w="100vw"
        background="linear-gradient(180deg, #130213 0%, #0A010A 100%)"
      >
        {currentStep === 1 && <SignUpPageOne incrementStep={incrementStep} />}
        
        {currentStep === 2 && <SignUpConfirmation />}
      </Box>
    </>
  );
}
