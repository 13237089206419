import React from 'react';

import { Text, Box, VStack, HStack, Link } from '@chakra-ui/react';
export default function TermsAndConditions() {
  return (
    <div>
      <Box>
        <VStack spacing={8} align="stretch">
          <Text fontSize="2xl" fontWeight="bold">
            Welcome to RASA World. Your purchase of tickets for and attending of
            RASA events is governed by the following Terms of Business (“RASA
            Terms of Business”).
          </Text>
          <Text fontSize="2xl" fontWeight="bold">
            Entering Events.
          </Text>
          <Text fontSize="lg">
            Upon entering the event, you and your possessions may be subject to
            a search. You grant permission for these searches and waive any
            potential legal claims that may result from them. Failure to provide
            consent for such searches may result in non-refundable denial of
            entry to the event, with no alternative compensation. You may be
            prohibited from bringing certain items into an event based on the
            venue’s entry policy which it and RASA may enforce in its or our
            sole discretion.
          </Text>
          <Text fontSize="2xl" fontWeight="bold">
            Host Venue Rules at Events.
          </Text>
          <Text fontSize="lg">
            You agree to abide fully by all guidelines, rules and regulations in
            place at any venue at which we host a RASA event, all of which shall
            apply to you upon entering said venues without exception. And all
            said guidelines, rules and regulations are deemed accepted by you as
            a condition to your purchase of a ticket to a RASA event, including
            any waives of liability or other disclaimers of liability which made
            be set forth therein.
          </Text>
          <Text fontSize="2xl" fontWeight="bold">
            Behavior at Events.
          </Text>
          <Text fontSize="lg">
            You agree to comply with all RASA&#39;s rules, policies, and terms
            and conditions. RASA has the right to refuse admission or remove
            anybody whose behavior is deemed disruptive, who uses vulgar or
            abusive language, or who fails to follow RASA&#39;s rules, policies,
            and terms and conditions. If you breach any of the conditions or
            restrictions, your right to attend the event may be revoked. A
            ticket is a revocable permit, and admission might be denied.
          </Text>
          <Text fontSize="2xl" fontWeight="bold">
            Changes to Lineups at Events.
          </Text>
          <Text fontSize="lg">
            RASA reserves the right to make changes as it deems necessary
            without penalty or liability and in such situations, no refunds,
            part refunds or alternative offers shall be made.
          </Text>
          <Text fontSize="2xl" fontWeight="bold">
            Photography and Recording at Events.
          </Text>
          <Text fontSize="lg">
            You agree with RASA that the event for which you have purchased
            tickets is a public event, that your appearance and actions within
            and outside the venue where the event takes place are public in
            nature, and that you have no expectation of privacy regarding your
            actions or behavior at the event. You grant permission to RASA to
            utilize your name, image, likeness, acts, appearance, movements, and
            statements in any live or recorded audio, video, or photographic
            display or other transmission, exhibition, publication or
            reproduction made of, or at, the event (regardless of whether
            before, during or after the event) for any purpose, in any manner,
            in any medium or context now known or hereafter developed, on a
            worldwide basis, without further authorization from, or compensation
            to, you or anyone acting on your behalf.
          </Text>
          <Text fontSize="2xl" fontWeight="bold">
            Assumption of Risks at Events.
          </Text>
          <Text fontSize="lg">
            You hereby fully understand and knowingly, voluntarily and
            irrevocably, assumes all risks, hazards and dangers, however caused,
            related to entry into, and presence in, at and around any one or
            more venues which host RASA events (including but not limited to
            death, personal injury, and damage and destruction to personal
            property), and which risks, hazards and dangers might result from
            the actions, inactions, or negligence of you, any of the RASA
            Released Parties (as defined below), or other third parties, and may
            occur or arise before, during or after any one or more events, and
            inside or outside of a venue. You hereby accept personal
            responsibility for your voluntary participation in/at any one or
            more RASA events. You hereby assume all and sole responsibility of
            claims and potential claims relating to such risks, hazards and
            dangers.
          </Text>
          <Text fontSize="2xl" fontWeight="bold">
            General Release
          </Text>
          <Text fontSize="lg">
            EXCEPT TO THE EXTENT THAT LAIBILTY CANNOT BE EXCLUDED AS A MATTER OF
            LAW, YOU HEREBY RELEASE, WAIVE, FOREVER DISCHARGE AND AGREE NOT TO
            SUE ANY RASA RELEASED PARTIES, TO THE MAXIMUM EXTENT PERMITTED BY
            LAW, FROM ANY AND ALL CLAIMS, LIABILITY AND DAMAGES OF WHATEVER KIND
            OR NATURE, INCLUDING BUT NOT LIMITED TO, THOSE CAUSED BY THE
            NEGLIGENCE, FAULT OR OTHER ACTION OR OMISSION OF ANY RASA RELEASED
            PARTIES, OR ARISING FROM OR IN ANY WAY CONNECTED WITH YOUR
            ATTENDANCE AT A RASA EVENT. IF ANY PART OF THIS LIMITATION ON
            LIABILITY IS FOUND TO BE INVALID OR UNENFORCEABLE FOR ANY REASON,
            THEN THE AGGREGATE LIABILITY OF THE RASA RELEASED PARTIES FOR
            LIABILITIES THAT OTHERWISE WOULD HAVE BEEN LIMITED SHALL NOT EXCEED
            TEN DOLLARS ($10.00).
          </Text>
          <Text fontSize="2xl" fontWeight="bold">
            Covid-19 Warning and Waiver of Claims
          </Text>
          <Text fontSize="lg">
            Any site where people meet carries an inherent risk of covid-19
            exposure. By participating in a RASA event, you agree to accept all
            associated risks, hazards, and dangers. You voluntarily waive any
            and all claims and potential claims against RASA and its employees,
            agents, affiliates, officers, directors, owners, vendors, and
            contractors (collectively, for purposes of these RASA Terms of
            Business, the &quot;RASA Released Parties&quot;) that arise from or
            relate in any way to, however caused, the risk of contracting or the
            actual or alleged contracting of covid-19 or any other communicable
            disease or illness, or that arise from or relate in any way to,
            however caused, the risk of exposure or actual or alleged exposure
            to a bacteria, virus, or other pathogen capable of causing covid-19
            or any other communicable disease or illness, regardless of whether
            the actual or alleged circumstances giving rise to any claim or
            potential claim occurred before, during, or after the event.
          </Text>
          <Text fontSize="2xl" fontWeight="bold">
            Force Majeure
          </Text>
          <Text fontSize="lg">
            RASA shall not be liable in damages for any delay or default in its
            performing of any of its obligations (including the holding of any
            event) if such delay or default is caused by conditions beyond
            RASA’s control including, but not limited to Acts of God, government
            restrictions (including the denial or cancellation of any export or
            other necessary license), wars, pandemics, insurrections and/or any
            other cause beyond the reasonable control of RASA.
          </Text>
          <Text fontSize="2xl" fontWeight="bold">
            Governing Law
          </Text>
          <Text fontSize="lg">
            You hereby understands and agrees that these RASA Terms of Business
            are subject to and will be construed in accordance with the laws of
            the State of New York, without regard to choice or conflict of law
            provisions.
          </Text>
          <Text fontSize="2xl" fontWeight="bold">
            Arbitration
          </Text>
          <Text fontSize="lg">
            You agree that: (a) any claim asserted by you, against any RASA
            Related Parties shall be resolved by mandatory, confidential, final,
            and binding arbitration to Judicial Arbitration and Mediation
            Services, Inc. (“JAMS”); (b) the arbitration shall proceed before a
            neutral, single arbitrator; (c) the arbitration shall be conducted
            in accordance with the JAMS Comprehensive Arbitration Rules and
            Procedures; (d) any and all issues relating or pertaining to
            arbitration or this arbitration clause, including but not limited to
            the threshold question of arbitrability or the enforceability or
            validity of this arbitration section, shall be delegated to the
            arbitrator selected pursuant hereto; (e) the arbitrator shall have
            the power to award any remedies, including reasonable attorneys’
            fees and costs, available under applicable law; (f) judgment upon
            the award rendered by the arbitrator may be entered in any court
            having jurisdiction; (g) the award may be vacated or modified only
            on the grounds specified in the FAA or other applicable law; and (h)
            any arbitration conducted pursuant to these RASA Terms of Business
            shall take place in New York County, New York.
          </Text>
          <Text fontSize="2xl" fontWeight="bold">
            Class Action Waiver
          </Text>
          <Text fontSize="lg">
            You agree that any and all disputes, including any claims you make
            against any RASA Related Parties, will be arbitrated on an
            individual basis alone. You expressly waive your right to litigate
            or arbitrate any claim against RASA as a class action,
            representative action, or class arbitration. Acknowledgement of
            Effect of Release You understand and acknowledge that by your
            accepting of these RASA Terms of Business you have given up certain
            legal rights and or possible claims which user might otherwise
            assert or maintain against any RASA Related Parties, including to
            but not limited to rights arising from or claims for the acts or
            omissions, fault or negligence of any RASA Related Parties.
          </Text>
        </VStack>
      </Box>
    </div>
  );
}
