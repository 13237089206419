export default function InfoIcon({}) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          stroke="currentColor"
          stroke-linecap="square"
          d="M10.5 10.5H12v5m-1.5 0H12m0 0h1.5m-1.5-7V8m7.5 4a7.5 7.5 0 1 1-15 0 7.5 7.5 0 0 1 15 0Z"
        ></path>
      </svg>
    );
  }
  
  InfoIcon.propTypes = {};
  