import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Text, Button } from '@chakra-ui/react';

const DescriptionComponent = ({ description, wordLimit = 300 }) => {
  const [isFullDescriptionShown, setIsFullDescriptionShown] = useState(false);

  const isLongDescription = description.split(' ').length > wordLimit;

  const truncateDescription = () => {
    return description.split(' ').slice(0, wordLimit).join(' ') + '...';
  };

  return (
    <Box>
      <Text color="gray.400" fontSize={['sm', '.96rem']} dangerouslySetInnerHTML={{ __html: description }} />
        
      {isLongDescription && (
        <Button 
          onClick={() => setIsFullDescriptionShown(!isFullDescriptionShown)}
          colorScheme="blue"
          variant="link"
          size="sm"
          mt={2}
        >
          {isFullDescriptionShown ? 'Read Less' : 'Read More'}
        </Button>
      )}
    </Box>
  );
};

DescriptionComponent.propTypes = {
  description: PropTypes.string.isRequired,
  wordLimit: PropTypes.number,
};

export default DescriptionComponent;
