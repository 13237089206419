import React from 'react';
import { Global, css } from '@emotion/react';
import { HStack, keyframes } from '@chakra-ui/react';

import {
  Text,
  Button,
  Box,
  VStack,
  Input,
  AvatarGroup,
  InputLeftAddon,
  Divider,
  FormControl,
  FormLabel,
  InputGroup,
  InputLeftElement,
  useToast
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useStytchUser, useStytch } from '@stytch/react';
import { useGuest } from '../api';
import { RasaLogo } from './logo';
import { Step1SVG } from './step1';
import { Step2SVG } from './step2';
import SVGComponent from './background';

export function IntoToEvent() {
  const gradient = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

  const toast = useToast();

  const stytchClient = useStytch();
  const stytchUser = useStytchUser();
  // Check if ?redirectToTicket=true is in the URL
  const redirectToTicket = window.location.search.includes(
    'redirectToTicket=true'
  );

  const [pw, setPw] = React.useState('');

  const { guest, loading } = useGuest(stytchUser?.user?.user_id);

  const [step, setStep] = React.useState(0);

  const [showButton, setShowButton] = React.useState(false);
  const { slug } = useParams();

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setShowButton(true);
    }, 500);

    return () => clearTimeout(timer); // Clear the timer when the component is unmounted
  }, []);

  const handleSubmit = async (e) => {
    console.log(e)
    if (pw.toLowerCase() === 'CONFESSIONS16'.toLowerCase()) {
      setStep(0)

    }
    else {
      toast({
        title: 'Wrong Password',
        description: 'Please try again',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  }

  return loading || !guest ? (
    <Box
      h="100vh"
      w="100vw"
      alignContent="center"
      justifyContent="center"
      bgSize="cover"
      bgPosition="center"
      display="flex"
      opacity={'0.9'}
      bgImage={'/images/inviteGifBackground.gif'}
    >
      <VStack>
        <Box marginTop="5vh">
          <RasaLogo />
        </Box>

        <Box
          w="80vw"
          h="80vh"
          bg="white"
          borderRadius="20px"
          boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <VStack>
            <Text
              fontSize="24px"
              fontWeight="bold"
              color="black"
              textAlign="center"
            >
              Loading...
            </Text>
          </VStack>
        </Box>
      </VStack>
    </Box>
  ) : (
    <>
      <Box
        h="100vh"
        w="100vw"
        alignContent="center"
        justifyContent="center"
        bgSize="cover"
        bgPosition="center"
        display="flex"
        opacity={'0.9'}
        bgColor={'#130213'}
      >
        {step === -1 && (
          <Box
            h="100vh"
            w="100vw"
            alignContent="center"
            justifyContent="center"
            bgSize="cover"
            bgPosition="center"
            display="flex"
            bgImage={
              'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/images/inviteGifBackground.gif)'
            }
            bgRepeat="no-repeat"
          >
            <VStack>
              <Box marginTop="3vh">
                <RasaLogo />
              </Box>

              <Box
                marginTop="3vh"
                h="80vh"
                w="90vw"
                bgColor="#0C0A29"
                borderRadius="18px"
              >
                <VStack gap="15px">
                  <Text
                    marginTop={'3vh'}
                    fontSize="3xl"
                    bgGradient="linear(to-l, #BDD7F180, #FFFFFF)"
                    bgClip="text"
                    textAlign={'center'}
                  >
                    This is a private
                    <br /> RASA event
                  </Text>
                  <form onSubmit={handleSubmit}>
                    <FormControl marginTop={'5vh'} marginLeft={'20px'}>
                      <FormLabel
                        fontWeight={'bold'}
                        fontSize={'xs'}
                        textColor={'#6F87A0EB'}
                        opacity={'0.92'}
                      >
                        PASSWORD
                      </FormLabel>
                      <Input
                        bgColor={'#050B1180'}
                        borderWidth={'1px'}
                        borderRadius={'16px'}
                        opacity="1.0"
                        marginBottom={'1vh'}
                        width={'80vw'}
                        type="text"
                        placeholder="Event Password"
                        name="password"
                        onChange={(e) => setPw(e.target.value)}
                      />
                      <Button
                        marginTop={'3vh'}
                        marginBottom={'3vh'}
                        borderColor={'gray.700'}
                        borderRadius={'71px'}
                        borderWidth={'1px'}
                        opacity={'0.92'}
                        width={'80vw'}
                        animation={`${gradient} 1s ease infinite`}
                        bgGradient="linear(to-l, #BDD7F180, #FFFFFF)"
                        bgClip="text"
                        type="submit"
                      >
                        Submit
                      </Button>
                    </FormControl>
                  </form>

                  
                </VStack>
              </Box>
            </VStack>
          </Box>
        )}
        {step === 0 && (
          <video
            autoPlay
            loop
            playsInline
            muted
            style={{
              position: 'absolute',
              width: '100%',
              left: '50%',
              top: '50%',
              height: '100%',
              objectFit: 'cover',
              transform: 'translate(-50%, -50%)',
              zIndex: '-1',
            }}
          >
            <source src={`/images/intro-video-${slug}.mp4`} type="video/mp4" />
          </video>
        )}
        <VStack>
          <Box
            h={'100vh'}
            alignContent={'center'}
            justifyContent={'center'}
            bgSize={'cover'}
            bgPosition={'center'}
            display={'flex'}
            
          >
            {step === 0 && showButton && (
              <Button
                variant={'outline'}
                borderColor={'white'}
                borderWidth={'4px'}
                borderRadius={'lg'}
                mt={'550px'}
                ml={'50vw'}
                onClick={() => {
                  if (redirectToTicket) {
                    window.location.href = `/specificEvent/${slug}?redirectToTicket=true`;
                  }
                  if (stytchUser?.user?.user_id) {
                    if (loading) return;

                    if (guest?.approvalStatus === 'approved') {
                      window.location.href = `/specificEvent/${slug}`;
                    } else if (guest?.approvalStatus === 'pending') {
                      window.location.href = '/confirmSignup';
                    } else {
                      window.location.href = '/signup';
                    }
                  } else {
                    window.location.href = '/login';
                  }
                }}
              >
                NEXT
              </Button>
            )}
          </Box>
        </VStack>
      </Box>
    </>
  );
}
